@import 'vars.scss';

.wrapper {
  padding-left: 8px;
}

.label {
  @at-root label#{&} {
    margin: 0;
  }

  & > span:last-of-type {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $grey-1;
  }
}

span.checkbox {
  padding: 0;
  margin-right: 12px;
}

.permission {
  display: flex;
  margin-bottom: 8px;
  align-items: center;
  padding-left: 8px;

  &__label {
    margin: 0;
    font-size: 16px;
    line-height: 24px;
    color: $grey-1;
  }

  &__icon {
    @at-root svg#{&} {
      margin-left: 26px;
      margin-right: 4px;
      width: 16px;
      height: 16px;
    }
  }
}

.select {
  width: 100%;
  margin: 8px 0;

  &__menu {
    & ul {
      padding: 0 !important;
    }
  }

  &__input {
    background-color: transparent;
    font-weight: 500 !important;
    border-radius: 12px !important;

    &:hover {
      background-color: $grey-6;
    }

    & > div {
      padding: 6px 24px 6px 12px;
    }

    & > fieldset {
      border: none;
    }

    &::before,
    &::after {
      display: none !important;
    }

    :global(div.Mui-disabled) > &__icon {
      & > path {
        fill: $grey-1;
      }
    }

    &__icon {
      @at-root div#{&} {
        width: 16px;
        height: 16px;
        top: calc(50% - 8px);
      }

      & > path {
        fill: $grey-1;
      }
    }
  }

  &__item {
    @at-root li#{&} {
      padding: 13px 16px;
    }

    &--selected {
      background-color: $grey-6 !important;
    }

    &__label {
      margin: 0 60px 0 0;
      font-size: 14px;
      line-height: 22px;
    }

    &__check {
      display: none;
      width: 16px !important;
      height: 16px !important;
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%);
    }

    &--selected > &__check {
      display: block;
    }
  }
}

.select > :global(div.Mui-focused) {
  background-color: transparent;

  &:hover {
    background-color: $grey-6;
  }
}

.select > :global(div.Mui-disabled) {
  background-color: transparent;
}
