@import 'vars.scss';

.userGuide {
  &__title {
    margin-top: 40px;
    margin-bottom: 24px;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
    color: $grey-1;
  }
  &__content {
    display: grid;
    gap: 24px;
    margin-bottom: 24px;
  }

  &__button {
    @at-root button#{&} {
      display: block;
      margin: 0 auto;
      padding: 12px 20px;
      border-radius: 8px;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      text-transform: capitalize;
    }
  }
}
