@import '/src/vars.scss';

.filledBookmark {
  & path {
    fill: $grey-1;
  }
}

.filledFlag {
  & path {
    fill: $grey-1;
  }
}
