@import 'vars.scss';

.list {
  @include scrollbar;
}

.loader {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 50px;
}
