@import 'vars.scss';

.wrapper {
  box-sizing: border-box;
  margin: 0 auto !important;
  height: calc(100% - 75px);
  max-width: 90%;
  padding-top: 20px;
  padding-bottom: 20px;
}

button.button {
  padding: 0;
}

.header {
  padding: 0 16px;
  flex-grow: 0;
  margin-bottom: 32px !important;

  & > div:first-of-type {
    width: calc(100% - 186px);
  }
}

.loaderWrapper {
  box-sizing: border-box;
  width: 100%;
  padding: 16px;
}

div.content {
  width: 100%;
  height: calc(100% - 82px);

  &__header {
    position: relative;
    width: 100%;
    padding: 0 16px 12px;

    &::after {
      content: '';
      display: block;
      width: calc(100% - 32px);
      height: 1px;
      position: absolute;
      left: 16px;
      bottom: 0;
      background-color: $grey-6;
    }

    & > div {
      font-size: 12px;
      line-height: 16px;
      color: rgba(3, 2, 41, 0.7);
    }

    & > div:last-of-type {
      text-align: right;
    }
  }

  &__body {
    width: 100%;
    height: calc(100% - 28px);
    overflow: hidden auto;
    @include scrollbar;
  }
}

.bulletin {
  cursor: pointer;
  position: relative;
  padding: 16px;
  border-radius: 8px;
  transition: 0.3s;
  font-size: 16px;
  line-height: 24px;

  &:hover {
    background-color: $grey-6;
  }

  &::after {
    content: '';
    display: block;
    width: calc(100% - 32px);
    height: 1px;
    position: absolute;
    left: 16px;
    bottom: 0;
    background-color: $grey-6;
  }

  &__sender {
    width: fit-content;
    margin: 0;
    transition: 0.3s;
    font-weight: 500;
    color: $grey-1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &:hover {
      color: $brand-green-2;
      text-decoration: underline;
    }
  }

  &__subject {
    font-weight: 500;
    color: $grey-1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__recipients {
    display: flex;
    align-items: center;

    & > svg {
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }

    & > p {
      margin: 0;
      color: $grey-3;
    }
  }

  &__statistic {
    display: flex;
    align-items: center;

    & > p {
      margin: 0;
      color: $grey-3;
    }

    & > div {
      height: 10px;
      width: 1px;
      border-radius: 99px;
      margin: 0 6px;
      background-color: $grey-5;
    }
  }

  &__date {
    color: $grey-3;
    text-align: right;
  }
}
