@import 'vars.scss';

.wrapper {
  box-sizing: border-box;
  margin: 0 auto !important;
  height: calc(100% - 75px);
  max-width: 90%;
  padding-top: 20px;
  padding-bottom: 20px;
}

.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.subheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 17px;
  width: 100%;
  padding: 20px 0;
  border-bottom: 2px solid $grey-6;
}

.list {
  display: grid;
  gap: 20px;
  height: calc(100% - 105px - 56px);
  overflow: hidden auto;

  @include scrollbar;
}

.loadingList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  height: calc(100% - 105px - 56px);
  overflow: hidden auto;

  @include scrollbar;
}
