@import 'vars.scss';

button.button {
  padding: 5px 9px;
  text-transform: none;
  border: 1px solid $grey-5;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: $grey-3;
  margin-right: -9px;
}

button.cancelButton {
  width: 124px;
  margin-right: 16px;
}

.modal {
  background: $white;
  padding: 24px 0 !important;
  min-width: unset;
  width: 560px;

  &__title {
    margin: 0 24px 24px !important;
  }

  &__header {
    margin: 10px 0px 0px 0px;
    padding: 0 24px;
  }

  &__edit {
    margin: 0px 24px;
    padding: 8px 4px 0px;
    border: 1px solid $grey-5;
    border-radius: 8px;
    background: $white;
  }
}

.selectAll {
  cursor: pointer;
  margin: 0;
  padding: 5px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: $grey-3;
}

.form {
  background: $white;
  display: flex;
  flex-direction: column;
  height: calc(100% - 52px);

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: nowrap;
    padding: 5px;
    min-height: 30vh;
    max-height: 40vh;
    overflow: auto;

    @include scrollbar;

    &__wrapper {
      padding: 0px 24px 0 32px;
      height: calc(200% - 118px);
    }
  }

  &__checkbox {
    &__wrapper {
      margin-bottom: 20px;
    }
  }

  &__actions {
    display: flex;
    padding: 24px 24px 0 24px;
    box-shadow: 0px -7px 16px rgba(0, 0, 0, 0.05);
  }
}

.filter {
  @at-root div#{&} {
    font-size: 16px;
    padding: 5px 0 0 8px;
    border-bottom: 1px solid $grey-5;
  }

  &--focused > fieldset {
    width: 100%;
    border: none;
  }

  &__input {
    @at-root input#{&} {
      padding: 5px 8px 5px 0;
    }
  }

  &__outline {
    border-radius: 8px;
    border-bottom: none;
  }

  &__icon {
    & path {
      fill: $grey-3;
    }

    &--action {
      cursor: pointer;
    }

    &--close {
      @at-root svg#{&} {
        width: 16px;
        height: 16px;
      }
    }

    &--hidden {
      visibility: hidden;
    }
  }
}

.checkbox {
  @at-root span#{&} {
    padding: 0;
    margin-right: 8px;
  }

  &__wrapper {
    @at-root label#{&} {
      margin: 0 0 8px 0;
    }
  }

  &__label {
    @at-root span#{&} {
      font-size: 20px;
      line-height: 28px;
      color: $grey-1;
    }
  }
}
