@import 'vars.scss';

.tabs {
  border-bottom: 2px solid $grey-6;

  &__indicator {
    @at-root span#{&} {
      background-color: $grey-1;
      min-width: 73px;
      display: flex;
    }
  }

  &__item {

    @at-root button#{&} {
      display: flex;
      margin-right: 34px;
      padding: 4px 0;
      font-size: 16px;
      line-height: 24px;
      text-transform: none;
      text-align: center;
      justify-content: center;
      color: $grey-4;
    }

    &#{&}--active {
      @at-root button#{&} {
        color: $grey-1;
      }
    }
  }

  &__content {
    height: calc(100% - 50px);
  }
}

.MuiTab_center {
  text-align: center; // Centra el texto dentro de cada pestaña
}