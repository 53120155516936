@import 'vars.scss';

.section {
  &__title {
    margin: 0;
    margin-bottom: 16px;
    font-size: 20px;
    letter-spacing: 0;
    font-weight: 700;
    line-height: 28px;
  }
  &__content {
    display: grid;
    gap: 16px;
  }
}

.sectionItem {
  &__header {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 8px 16px;
    border: 1px solid $grey-5;
    border-radius: 8px;

    &:hover {
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
    }

    &--open {
      border: 1px solid $brand-green-2;
      border-radius: 8px;
      box-shadow: 0 4px 14px rgba(0, 0, 0, 0.06);

      & svg {
        transform: rotate(180deg);
      }
    }
  }

  &__title {
    margin: 0;
    font-size: 16px;
    letter-spacing: 0;
    font-weight: 500;
    line-height: 24px;
    color: $grey-1;
  }

  &__content {
    height: 0;
    overflow: hidden;
    transition: all 0.3s;
    &--open {
      height: fit-content;
    }

    & ul {
      margin-top: 16px;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: $grey-1;
    }

    & img {
      margin: 24px 0;
      max-width: 600px;
    }

    & h3 {
      margin-top: 24px;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
    }
  }
}
