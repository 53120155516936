@import 'vars.scss';

button.button {
  padding: 5px 20px 5px 12px;
  overflow: hidden;
  border: 1px solid $grey-5;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  text-transform: none;
  white-space: nowrap;
  color: $grey-1;

  & > span:first-of-type {
    margin-left: 0;
  }

  & > span:last-of-type {
    border-radius: unset;
  }
}

.modal {
  width: fit-content;
}

.form {
  display: grid;
  gap: 16px;

  &__control {
    display: flex;
    justify-content: flex-end;
    padding-top: 24px;
  }
}

.textInput {
  & > label {
    display: block;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    color: $grey-1;
    & > span {
      color: $error;
    }
  }

  & > input {
    width: 498px;
    padding: 12px 16px;
    border: 1px solid $grey-5;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: $grey-1;

    outline: none;
    &::placeholder {
      color: $grey-4;
    }
  }
}
