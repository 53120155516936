@import 'vars.scss';

.wrapper {
  padding-top: 4px;
}

.staffWrapper {
  box-sizing: border-box;
  // margin: 0 auto !important;
  height: 95%;
  max-width: 1105px;
  padding-top: 8px;
}

.list {
  height: calc(100% - 76px - 56px);
  overflow: hidden auto;

  @include scrollbar;
}

.role {
  cursor: pointer;
  transition: 0.3s;
  padding: 20px 13px 20px 20px;
  border-radius: 24px;
  background: linear-gradient(97.42deg, $grey-6 6.15%, $grey-6 96.09%);
  border: 1px solid $grey-5;

  &:not(:last-of-type) {
    margin-bottom: 8px;
  }

  &:hover {
    background-color: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.3),
        rgba(255, 255, 255, 0.3)
      ),
      linear-gradient(97.42deg, $grey-6 6.15%, $grey-6 96.09%);
    box-shadow: 0px 6px 13px rgba(228, 228, 228, 0.7);
  }

  &__title {
    margin: 0;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: $grey-1;
  }

  &__officers {
    margin: 0;
    font-size: 14px;
    line-height: 22px;
    color: $grey-1;
  }

  &:hover svg#{&}__open {
    display: inline-block;
  }

  &__open {
    @at-root svg#{&} {
      display: none;
      width: 24px;
      height: 24px;
      margin-left: 16px;

      & path {
        fill: $grey-4;
      }
    }
  }
}

button.button {
  float: right;
  padding: 5px 20px 5px 12px;
  margin-bottom: 8px;
  border-radius: 8px;
  overflow: hidden;
  text-transform: none;
  font-size: 16px;
  line-height: 24px;
  white-space: nowrap;
  color: $grey-1;
  border: none;
  transform: translateX(15px);

  & > span:last-of-type {
    border-radius: unset;
  }
}

button.cancelButton {
  width: 124px;
  margin-right: 16px;
}

button.createButton {
  float: right;
  padding: 5px 20px 5px 12px;
  margin-bottom: 8px;
  border-radius: 8px;
  overflow: hidden;
  text-transform: none;
  font-size: 16px;
  line-height: 24px;
  white-space: nowrap;
  color: $grey-1;
  border: none;
  transform: translateX(15px);

  & > span:last-of-type {
    border-radius: unset;
  }
}

.title {
  margin: 0 32px 18px 32px !important;
}

.modal {
  height: 80%;
  padding: 24px 0 !important;
}

.form {
  display: flex;
  flex-direction: column;
  height: calc(100% - 52px);

  &__content {
    padding: 6px 32px 0 32px;
    height: calc(100% - 70px);
    overflow: auto;

    @include scrollbar;
  }

  &__input {
    margin-bottom: 20px;
  }

  &__subtitle {
    margin: 20px 0 16px 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $grey-1;
  }

  &__permissions {
    flex-grow: 1;
    @include scrollbar;
  }

  &__actions {
    display: flex;
    padding: 24px 24px 0 24px;
    box-shadow: 0px -7px 16px rgba(0, 0, 0, 0.05);
  }
}
