@import 'vars.scss';

.map_list {
  position: relative;
  width: 100%;
  height: calc(100% - 190px);
  overflow: hidden auto;
  @include scrollbar;

  .ReactVirtualized__Grid__innerScrollContainer {
    overflow: scroll !important;
  }

  .list_item {
    width: 100%;
    height: max-content;
    border-bottom: 1px solid $grey-5;

    .question__item {
      display: flex;
      position: relative;
      width: 98%;
      padding: 20px;

      &__number {
        position: relative;
        display: flex;
        width: 48px;
        height: 48px;
        background: #ebebef;
        border-radius: 8px;
        justify-content: center;
        align-items: center;
      }

      &__title {
        display: flex;
        position: relative;
        left: 20px;
        width: 100%;
        height: 24px;
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        align-items: center;
        color: $grey-1;
      }

      &__stats {
        // display: flex;
        position: absolute;
        left: 87px;
        top: 45px;
        width: 100%;
        height: 24px;
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        align-items: center;
        color: $grey-4;
      }
    }

    .responses_item {
      width: 100%;
      height: max-content;
      top: 100px;
      padding: 20px 0px 50px 5px;
      text-align: center;

      &__option {
        width: 81%;
        padding: 5px 0 20px 90px;

      }

      &__rating {
        display: inline-block;
        margin: 0 2%;
        padding: 5px 0 0px 15px;
      }
    }
  }
}

.survey_title {
  display: none;
}

@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html,
  body {
    height: initial !important;
  }
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }
  .survey_title {
    display: block;
  }
}

@page {
  size: auto;
  margin: 20mm;
}
