@import 'vars.scss';

.input {
  width: calc(100% - 32px);
  padding: 0;
  font-size: 14px;
  line-height: 22px;
  border: none;
  outline: none;
  color: $grey-1;
  background-color: $grey-6;

  &::placeholder {
    color: $grey-4;
  }

  &__wrapper {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    height: 34px;
    margin: 16px 8px;
    padding: 10px 8px;
    border-radius: 8px;
    border: 1px solid $grey-5;
    background-color: $grey-6;
  }

  &__icon {
    @at-root svg#{&} {
      width: 16px;
      height: 16px;
      margin: 0 8px 0 0;

      & > path {
        stroke: $grey-1;
      }
    }
  }
}
