@import 'vars.scss';

.form {
  flex-grow: 1;
}

.input {
  width: 100%;
  height: 48px !important;
  border-radius: 16px !important;

  &__wrapper {
    width: 100%;
  }

  &--focused &__outline {
    border-color: $grey-5 !important;
  }

  & > div,
  & > input {
    box-sizing: border-box;
    height: 100%;
  }
}

.clearIcon {
  cursor: pointer;
}
