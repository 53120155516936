@import 'vars.scss';

button.button {
  padding: 5px 9px;
  text-transform: none;
  border: 1px solid $grey-5;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: $grey-3;
  margin-right: -9px;
}

button.cancelButton {
  width: 124px;
  margin-right: 16px;
}

.create {
  &__modal {
    position: absolute;
    top: 21px;
    right: 24px;
  }

  &__button {
    @at-root button#{&} {
      position: absolute;
      top: 21px;
      right: 26px;
      padding: 5px 20px 5px 12px;
      border-radius: 8px;
      overflow: hidden;
      text-transform: none;
      font-size: 16px;
      line-height: 24px;
      white-space: nowrap;
      color: $grey-3;
      border: none;
      transform: translateX(15px);

      & > span:last-of-type {
        border-radius: unset;
      }
    }

    &__icon > path {
      fill: $grey-3 !important;
    }
  }
}

.title {
  margin: 0 32px !important;
  padding: 5px 0px;
}

.modal {
  width: 560px;
  max-height: 75%;
  padding: 24px 0 !important;
}

.form {
  display: flex;
  flex-direction: column;
  height: calc(100% - 52px);

  &__content {
    height: 100%;

    &__roles {
      height: 25vh;
      padding: 18px 12px;
      margin-bottom: 15px;
      border: 1px solid $grey-5;
      border-radius: 8px;
      overflow: auto;
    }
    &__users {
      max-height: 20vh;
      overflow: auto;

      &__item {
        padding: 18px 12px;
        border-radius: 8px;
        margin-bottom: 10px;
        background: $grey-6;
      }
    }

    &__wrapper {
      height: calc(100% - 70px);
      padding: 6px 32px 0 32px;
    }
  }

  &__actions {
    display: flex;
    padding: 14px 24px 0px 24px;
    box-shadow: 0px -7px 16px rgba(0, 0, 0, 0.05);
  }
}

.checkbox {
  @at-root span#{&} {
    padding: 0;
    margin-right: 8px;
  }

  &__wrapper {
    margin-bottom: 8px;
    margin-left: 13px;
  }

  &__header {
    margin: 0 !important;
  }

  &__label {
    @at-root span#{&} {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      color: $grey-1;
    }
  }

  &__description {
    margin: 0 0 0 32px;
    font-size: 14px;
    line-height: 22px;
    color: $grey-4;
  }
}

.selectAll {
  cursor: pointer;
  margin: 0;
  padding: 5px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: $grey-3;
}
