@import 'vars.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.text {
  margin: 20px 0 0 0;
  font-size: 16px;
  line-height: 24px;
  color: $grey-1;
  text-align: center;
}
