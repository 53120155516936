@import 'vars.scss';

.tipIcon {
  cursor: pointer;
}

.tipModal {
  @at-root div#{&} {
    padding: 24px;
    width: 468px;
  }

  &__text {
    margin: 0 0 8px 0;
    font-size: 16px;
    line-height: 24px;
    color: $grey-1;
  }

  &__list {
    padding: 0 0 0 27px;
    margin: 0 0 24px 0;

    &__item {
      margin-bottom: 8px;
      font-size: 16px;
      line-height: 24px;
      color: $grey-1;
    }
  }
}
