// Colors

$grey-1: #101010;
$grey-3: #686873;
$grey-4: #9191a1;
$grey-5: #ebebef;
$grey-6: #f2f4f4;
$white: #ffffff;

$brand-green-1: #03a882;
$brand-green-2: #038768;
$brand-green-3: #025d48;

$brand-purple: #3f1892;

$success: #03a882;
$error: #df093c;
$warning: #ffc226;
$info: #551cf6;

// Mixins

@mixin scrollbar() {
  &::-webkit-scrollbar {
    height: 4px;
    width: 4px;
    background: transparent;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #9191a1;
    border-radius: 15px;
    transform: translateX(-40px);
  }
}

@mixin loadingAnimation() {
  overflow: hidden;

  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 50%;
    height: 600%;
    background: linear-gradient(
      90deg,
      transparent,
      rgba(255, 255, 255, 0.5),
      transparent
    );
    transform: translateY(-10%) translateX(-55%) rotate(45deg);
    animation: loading 1s infinite;
  }

  @keyframes loading {
    0% {
      transform: translateY(-10%) translateX(-55%) rotate(45deg);
    }

    100% {
      transform: translateY(10%) translateX(225%) rotate(45deg);
    }
  }
}

@mixin iconButton($border: true) {
  @at-root button#{&} {
    min-width: unset;
    padding: 6px;
    border-radius: 8px;

    @if ($border == true) {
      border: 1px solid $grey-5;
    }
  }

  & > svg {
    color: $grey-1;
    width: 20px;
    height: 20px;
  }

  & > span {
    color: $grey-1;
  }
}

@mixin formStatusesColor() {
  background-color: $grey-5;

  &--OPEN {
    background-color: $info;
  }
  &--CLOSED {
    background-color: $grey-4;
  }
  &--PENDING {
    background-color: $brand-green-1;
  }
  &--RESOLVE {
    background-color: $brand-purple;
  }
  &--OTHER {
    background-color: $grey-3;
  }
}

@mixin textEllipsis() {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
