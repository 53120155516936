@import 'vars.scss';

.modal {
  @at-root div#{&} {
    width: 580px;
    padding: 24px;
  }
}

.form {
  padding: 24px 0 0 0;
}

.button {
  width: 124px !important;

  &__wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 66px;
  }
}

.infoStep {
  &__heading {
    margin: 0 0 16px 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $grey-1;
  }

  &__input {
    margin-bottom: 16px !important;
  }
}

.selectAll {
  cursor: pointer;
  user-select: none;
  margin: 0;
  padding: 5px 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: $grey-3;
}

.nameEmail {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  padding: 16px;
  border-radius: 8px;
  background-color: $grey-6;

  & > p {
    margin: 0;
    width: calc(50% - 4px);
    font-size: 16px;
    line-height: 24px;
    color: $grey-1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  & > p:last-of-type {
    text-align: right;
  }
}

.filter {
  @at-root div#{&} {
    font-size: 16px;
    border-radius: 8px;
    padding: 0 0 0 8px;
  }

  &--focused > fieldset {
    border-width: 1px !important;
    border-radius: 8px;
  }

  &__input {
    @at-root input#{&} {
      padding: 5px 8px 5px 0;
    }
  }

  &__outline {
    border-radius: 8px;
  }

  &__icon {
    & path {
      fill: $grey-3;
    }

    &--action {
      cursor: pointer;
    }

    &--close {
      @at-root svg#{&} {
        width: 16px;
        height: 16px;
      }
    }

    &--hidden {
      visibility: hidden;
    }
  }
}

.checkbox {
  @at-root span#{&} {
    padding: 0;
    margin-right: 8px;
  }

  &__wrapper {
    @at-root label#{&} {
      margin: 0 0 8px 0;
    }
  }

  &__label {
    @at-root span#{&} {
      font-size: 20px;
      line-height: 28px;
      color: $grey-1;
    }
  }
}

.checkboxList {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.facilitiesStep {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
  }
}

.rolesStep {
  &__header {
    margin-bottom: 8px;
  }

  &__submit {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    margin-top: 94px;
  }

  &__btnWrapper {
    display: flex;
    justify-content: flex-end;
  }
}
