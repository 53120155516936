@import 'vars.scss';

.wrapper {
  flex-grow: 1;
}

.input {
  @at-root div#{&} {
    padding: 0 16px 0 16px;
    border-radius: 16px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.06);
  }

  &__wrapper {
    width: 100%;
  }

  &__outline {
    border: 1px solid $grey-5 !important;
  }

  & input {
    padding: 12px 0 12px 0;
    &::placeholder {
      opacity: 1;
      color: $grey-4;
    }
  }

  &__icon {
    & > path {
      fill: $grey-4;
    }
  }
}

.clearIcon {
  cursor: pointer;
}
