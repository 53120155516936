@import 'vars.scss';

.wrapper {
  box-sizing: border-box;
  margin: 0 auto !important;
  height: calc(100vh - 170px);
  max-width: 90%;
  padding-top: 20px;
  padding-bottom: 20px;
}

div.header {
  margin: 8px 0;
  padding: 9px 16px;

  & > div {
    box-sizing: border-box;
    font-size: 14px;
    line-height: 22px;
    color: $grey-4;
  }

  & > div:last-of-type {
    text-align: end;
  }
}

.content {
  //height: calc(100% - 130px);
  overflow: hidden auto;
  @include scrollbar;
}

.statusItem {
  display: flex;
  align-items: center;
  height: 72px;
  padding: 0 16px;
  border-top: 1px solid $grey-5;

  &:hover {
    cursor: pointer;
    border-radius: 16px;
    background: $grey-6;

    transition: background 0.4s;
  }

  &__status {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    color: $brand-green-2;

    &--blocked {
      color: $error;
    }

    & > span {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      color: $grey-4;
    }
  }

  &__reason {
    display: inline-block;
    padding: 1px 12px;
    border-radius: 12px;
    background: $info;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    color: $white;
  }

  &__date {
    display: flex;
    justify-content: flex-end;
    font-size: 14px;
    line-height: 22px;
    text-align: right;
    color: $grey-4;
  }
}

.completedBy {
  display: flex;
  align-items: center;

  &__user {
    margin-right: 16px;

    &--kiwicop {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: $grey-6;
    }

    & > span {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: $white;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 16px;
  }

  &__name {
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    text-align: left;
    color: $grey-1;

    &:hover {
      text-decoration: underline;
    }

    &--kiwicop {
      text-decoration: none !important;
    }
  }

  &__phone {
    font-size: 14px;
    line-height: 22px;
    text-align: left;
    color: $grey-4;
  }
}
