@import 'vars.scss';

.wrapper {
  display: flex;
  width: 100%;
  height: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 25px;
  border-bottom: 1px solid $grey-5;

  &__left {
    & > span {
      max-width: 350px;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      color: $grey-1;

      @include textEllipsis();
    }
  }

  &__right {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__backBtn {
    @include iconButton(false);

    @at-root button#{&} {
      margin-right: 8px;
    }
  }

  &__sentBtn {
    @at-root button#{&} {
      padding: 5px 20px;
      font-size: 14px;
      letter-spacing: 0;
      font-weight: 500;
      line-height: 22px;
    }
  }

  &__iconBtn {
    @include iconButton();

    @at-root button#{&} {
      width: 34px;
      height: 34px;
    }
  }
}

.content {
  width: calc(100% - 360px);

  &__body {
    @include scrollbar();
    height: calc(100vh - 170px);
    overflow-y: auto;
    overflow-x: hidden;
    padding: 40px 20px;
  }
}

.sidebar {
  width: 360px;
  padding: 20px;
  border-left: 1px solid $grey-5;
}

.addQuestion {
  &__form {
    display: grid;
    gap: 40px;
  }

  &__btn {
    @at-root button#{&} {
      display: block;
      margin-left: auto;
    }
  }
}

.recipients {
  &__header {
    margin: 0 0 2px 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

  &__tip {
    margin: 0 0 16px 0;
    font-size: 14px;
    line-height: 22px;
    color: $grey-3;
  }

  &__type {
    margin-bottom: 18px !important;
  }

  &__input {
    @at-root div#{&} {
      margin-bottom: 16px;
    }
  }

  &__placeholder {
    height: 124px;
    border-radius: 8px;
    background: $grey-6;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__icon {
      @at-root svg#{&} {
        width: 40px;
        height: 40px;
      }
    }

    &__text {
      margin: 4px 0 0 0;
      color: $grey-3;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
    }

    &__subtext {
      margin: 0;
      font-size: 12px;
      line-height: 20px;
      color: $grey-4;
    }
  }
}

.actions {
  margin-top: 32px;
}
