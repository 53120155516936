@import 'vars.scss';

.wrapper {
  display: flex;
  flex-wrap: nowrap;
  justify-content: stretch;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.content {
  flex-grow: 1;
  max-width: calc(100% - 300px);
  height: 100%;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-bottom: 1px solid $grey-5;

    &__surveyTitle {
      display: flex;
      flex-direction: column;

      &__surveyName {
        text-decoration: underline;
        font-size: 16px;
        margin-bottom: 1%;
      }

      &__resultsOwner {
        font-size: 14px;
      }
    }

    &__left {
      display: flex;
      align-items: center;

      font & > span {
        max-width: 350px;
        overflow: hidden;
        font-size: 14px;
        letter-spacing: 0;
        font-weight: 500;
        line-height: 22px;
        text-align: left;
        text-overflow: ellipsis;
        color: $grey-1;

        white-space: nowrap;
      }
    }

    &__backButton {
      @include iconButton(false);

      @at-root button#{&} {
        margin-right: 8px;
      }
    }

    &__actions {
      display: flex;
      justify-content: flex-end;

      &__item {
        @include iconButton();

        @at-root button#{&} {
          margin-right: 8px;
        }
      }
    }

    &__closeButton {
      padding: 6px 20px !important;
      font-size: 14px !important;
      font-weight: 500;
      line-height: 22px !important;
    }
  }

  &__results {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
}

.sidebar {
  flex-direction: column;
  box-sizing: border-box;
  width: 40vw;
  min-width: 300px;
  max-width: 360px;
  height: 95vh;
  padding: 20px 20px 0 20px;
  border-left: 1px solid $grey-5;
  overflow: hidden;

  &__divider {
    margin: 5% 0;
    width: 100%;
    height: 1px;
    background-color: $grey-5;
  }

  &__searchAndSummaryDiv {
    width: 100%;
    height: 20%;
  }

  &__responses {
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 50%;
    margin-top: 5%;
    overflow-y: auto;
    @include scrollbar;

    &__item {
      box-sizing: border-box;
      cursor: pointer;
      display: flex;
      // flex-direction: column;
      justify-content: left;
      margin-bottom: 8px;
      width: 100%;
      height: 72px;
      padding: 15px;
      border-radius: 16px;
      border: 1px solid $grey-5;
      background-color: $white;
      align-items: center;
      transition: 0.3s;

      &__ownerName {
        margin-left: 7%;
        display: flex;
        flex-direction: column;

        &__ownerId {
          font-size: 11px;
        }
      }

      &:hover {
        box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.06);
      }
    }
  }

  &__tabContent {
    flex: 1;
    padding: 20px 0 0 0;
  }
}

.inmate {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  &__avatar {
    @at-root div#{&} {
      margin-right: 16px;
    }
  }

  &__name,
  &__id {
    margin: 0;
    font-size: 14px;
    line-height: 22px;
  }

  &__name {
    font-weight: 500;
    color: $grey-1;
  }

  &__id {
    color: $grey-4;
  }
}

.info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;

  width: 100%;
  height: 20%;

  &__item {
    width: 100%;
    height: 15%;

    &__label {
      position: relative;
      width: 91.98px;
      height: 24px;
      left: 0px;
      top: 0px;

      font-family: 'DM Sans';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;

      display: flex;
      align-items: center;

      color: $grey-1;
    }

    &__value {
      position: relative;
      display: flex;
      width: 149.79px;
      top: 0px;
      height: 24px;

      font-family: 'DM Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;

      align-items: center;
      text-align: end;

      margin-left: auto;
      margin-right: 0;

      color: $grey-3;

      span {
        margin: 0px 3px;
      }
    }
  }

  & > p {
    max-width: 50%;
    overflow: hidden;
    text-overflow: ellipsis;

    white-space: nowrap;
  }

  & > div {
    display: flex;
    align-items: center;
  }
}

.accordion {
  width: 100%;
  max-height: 156px;
  overflow: auto;
  background-color: $grey-6 !important;
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;

  @include scrollbar;
}

.singleRecipient {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
}
