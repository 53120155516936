@import 'vars.scss';

.wrapper {
  box-sizing: border-box;
  margin: 0 auto !important;
  height: 100%;
  width: 90%;
  padding-top: 20px;
  padding-bottom: 20px;
}

.wrapperForm {
  box-sizing: border-box;
  height: calc(100% - 47px);
}

.subheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 17px;
  width: 100%;
}

.request {
  box-sizing: border-box;
  //cursor: pointer;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 72px;
  padding: 13px 16px 13px 16px;
  border-radius: 16px;
  border: 1px solid $grey-5;
  background-color: $white;
  transition: 0.3s;

  &:hover {
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.06);
  }

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $grey-1;
  }
}

.list {
  height: calc(100% - 47px);
  overflow: hidden scroll;

  @include scrollbar;
}

div.listHeader {
  padding: 0 16px 17px 16px;

  & > div {
    font-size: 14px;
    line-height: 22px;
    color: $grey-4;
    white-space: nowrap;
  }
}
