@import 'vars.scss';

.navigation {
  display: flex;
  max-width: 80%;
  &__icon {
    @at-root svg#{&} {
      fill: $grey-1;
    }
  }
  &__item {
    text-decoration: none;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: $grey-1;
  }
}
