@import 'vars.scss';

.input {
    font-size: 16px !important;
    line-height: 24px !important;
    color: $grey-4;
    & > div:first-of-type {
        height: 48px !important;
    }

    input {
        cursor: pointer;
        padding: 12px 16px;
    }

    fieldset {
        border-radius: 8px !important;
    }

    label[data-shrink='false'] {
        line-height: 1;
        color: $grey-4;
    }
}

.dateRangePicker{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
