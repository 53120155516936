@import 'vars.scss';

.field {
  & > p {
    margin-left: 0;
  }

  & > label {
    margin-top: -4px;
  }

  &__input {
    @at-root input#{&} {
      padding: 12px 16px;
      color: $grey-1;
    }

    &__wrapper {
      @at-root div#{&} {
        border-radius: 8px;
      }
    }
  }

  &--disableOutline {
    & > label {
      color: $grey-4 !important;
    }

    & > :global(.MuiInputLabel-shrink) {
      color: $grey-1 !important;
    }
  }

  &--disableOutline &--focused &__outline {
    border: 1px solid $grey-5 !important;
  }
}
