@import 'vars.scss';

.modal {
  @at-root div#{&} {
    padding: 24px;
    width: 468px;
    height: 248px;
  }
}

.input {
  outline: none;
  box-sizing: border-box;
  width: 100%;
  padding: 12px 16px;
  border: 1px solid $grey-1;
  border-radius: 8px;
  font-size: 16px;
  line-height: 24px;
  color: $grey-1;

  &:focus {
    border: 1px solid $brand-green-2 !important;
  }

  &::placeholder {
    color: $grey-4;
  }

  &__wrapper {
    margin-bottom: 24px;
  }

  &__label {
    display: block;
    margin-bottom: 8px;
    font-size: 16px;
    line-height: 24px;
    color: $grey-1;
  }
}
