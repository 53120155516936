@import 'vars.scss';

.switch {
  &__wrapper {
    display: flex;
    align-items: center;
  }

  &__label {
    display: block;
    margin-left: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color:$grey-1;
  }
}
