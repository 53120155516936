@import 'vars.scss';

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 24px;
  &__left {
    display: block;
  }

  &__status {
    margin: 8px 0;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    color: $grey-1;
  }

  &__chat {
    margin: 0;
    font-size: 16px;
    line-height: 24px;
    color: $grey-4;
  }
}

.content {
  max-height: 500px;
  overflow-y: auto;

  @include scrollbar();
}

.block {
  &:not(:last-child) {
    margin-bottom: 26px;
  }

  &__title {
    margin: 0;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    color: $grey-1;
  }

  &__text {
    margin: 0;
    font-size: 16px;
    line-height: 24px;
  }

  &__reason {
    display: flex;
    justify-content: center;
    margin: 0;
    width: 57px;
    height: 22px;
    padding: 0 12px;
    border-radius: 12px;
    background: $info;
    color: $white;
  }
}

.completedBy {
  display: flex;
  align-items: center;
  &__avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
    width: 40px;
    height: 40px;
    border-radius: 180px;
    background: $grey-6;
  }

  &__nameWrapper {
    display: flex;
  }

  &__name,
  &__role {
    cursor: pointer;
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    color: $grey-1;
    &:hover {
      text-decoration: underline;
    }
    &--kiwicop {
      cursor: default !important;
      text-decoration: none !important;
    }
  }

  &__role {
    cursor: default !important;
    text-decoration: none !important;
    margin-left: 2px;
    color: $brand-green-2;
  }
}
