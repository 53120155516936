@import 'vars.scss';

.wrapper {
  margin: 11px 0 0 0;
  width: 100%;
}

.placeholder {
  cursor: pointer;
  padding: 5px 0;
  margin: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: $grey-3;
}

.dropzone {
  cursor: pointer;
  width: 100%;
  height: 98px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: $grey-6;
  border: 1px dashed $grey-1;
  border-radius: 8px;

  &__label {
    margin: 28px auto 5px auto;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $grey-1;

    &__highlight {
      color: $brand-green-1;
    }
  }

  &__cancel {
    margin: 0 0 12px 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $grey-4;
  }
}

.file__layout {
  display: inline-block;
}

.file {
  padding: 6px 9.33px 6px 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  background-color: $grey-6;
  border-radius: 16px;

  &__info {
    margin: 0 9.33px 0 10px;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    font-weight: 500;
    font-size: 10px;
    line-height: 20px;

    &__ext {
      flex-shrink: 0;
      color: $grey-4;
    }
  }

  &__remove {
    color: $grey-4;
  }
}
