@import 'vars.scss';

button.button {
  margin-left: 20px;
  padding: 10px 12px;
  border: 1px solid $grey-5;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-transform: none;
  color: $grey-1;

  & > span {
    margin-left: 0;
  }
}

.modal {
  min-width: fit-content;
}

.blocksWrapper {
  display: flex;
  flex-wrap: nowrap;
  justify-content: stretch;
  gap: 24px;
  width: 100%;
  height: 335px;
}

.block {
  width: 245px;

  &__label {
    display: flex;
    align-items: center;
    margin: 0 0 8px 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: $grey-1;

    & > span {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 0 0 8px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: $grey-1;
      color: $white;

      & > span {
        display: block;
        text-align: center;
      }
    }
  }

  &__content {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 12px 10px 4px;
    height: calc(100% - 30px);
    border-radius: 8px;
    border: 1px solid $grey-5;
    overflow: hidden auto;

    @include scrollbar;
  }
}

.submit {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;

  & > button:first-of-type {
    width: 124px !important;
  }
}
