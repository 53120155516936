@import 'vars.scss';

.modal {
  padding: 30px;
  width: 48%;

  &--block {
    width: 600px;
    height: unset;
  }

  &__closeButton,
  &__blockButton {
    height: 44px !important;
    width: 124px !important;
  }

  &__transcriptButton {
    height: 34px !important;
    width: 134px !important;
    font-size: 14px !important;
    border-radius: 8px !important;
  }

  &__title {
    margin-bottom: 24px !important;
  }
}

.header {
  margin-bottom: 24px;

  &__info {
    display: flex;
    align-content: center;
    align-items: center;
  }

  &__infoDivider {
    margin: 0 16px;
    width: 1px;
    height: 44px;
    background-color: $grey-5;
  }

  &__controls {
    display: flex;
    align-content: center;
    align-items: center;
  }

  &__avatar {
    @at-root div#{&} {
      margin-right: 20px;
      width: 36px;
      height: 36px;
      font-size: 18px;
    }
  }

  &__author {
    & > p {
      margin: 0;
      font-size: 14px;
      line-height: 22px;
    }

    & > p:first-of-type {
      cursor: pointer;
      font-weight: 500;
      color: $grey-1;

      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }

    & > p:last-of-type {
      font-weight: 400;
      color: $grey-4;
    }
  }

  &__sentBy {
    & > p {
      margin: 0;
      font-size: 14px;
      line-height: 22px;
    }

    & > p:first-of-type {
      font-weight: 500;
      color: $grey-1;
    }

    & > p:last-of-type {
      font-weight: 400;
      color: $grey-4;
    }
  }

  &__showButton {
    @at-root button#{&} {
      border-radius: 12px;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      text-transform: none;
      color: $grey-1;
    }
  }

  &__divider {
    width: 1px;
    height: 16px;
    background-color: $grey-5;
    margin: 0 5px;
  }

  &__action {
    @include iconButton();
    @at-root button#{&} {
      border: none;
    }

    & > svg > {
      path,
      rect {
        color: $grey-1;
      }
    }

    &__filled svg > path,
    &__filled {
      svg > rect {
        fill: $grey-1;
        stroke: $grey-1 !important;
      }
    }

    &:disabled {
      svg > path,
      rect {
        color: $grey-3 !important;
      }
    }
  }
}

.content {
  display: flex;
  min-width: 300px;
  max-width: 500px;
  flex-direction: column;
  margin: 0 auto;
  overflow: hidden auto;
  @include scrollbar();

  &__wrapper {
    padding: 10px;
    text-align: center;

    &__transcription {
      padding: 10%;
    }

    &__audioplayer {
      position: relative;
      display: flex;
      margin: 40px 0px;
      padding: 2%;
      vertical-align: middle;
      width: 96%;
      height: 40px;
      border-radius: 0px 8px 8px 8px;
      background-color: $grey-6;
    }

    & > img {
      display: block;
      margin: 0 auto;
      width: 100%;
      height: 500px;
      border-radius: 8px;
      object-fit: contain;
    }

    & > video {
      display: block;
      margin: 0 auto;
      height: 100%;
      border-radius: 8px;
    }
  }
}

.info {
  width: 100%;
  margin-bottom: 20px;

  span:first-child {
    color: $error;
  }

  span {
    color: $grey-4;
  }
}

.preview {
  margin-bottom: 40px;

  & > img {
    object-fit: cover;
    margin-right: 40px;
    width: 112px;
    height: 112px;
    border-radius: 8px;
  }

  & > svg {
    object-fit: cover;
    margin-right: 40px;
    width: 112px;
    height: 112px;
    border-radius: 8px;
  }

  &__header {
    margin: 0 0 5px 0;
    font-size: 16px;
    line-height: 24px;
    color: $grey-1;
  }

  &__item {
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 4px;

    & > p {
      margin: 0;
      font-size: 14px;
      line-height: 22px;
    }

    & > p:first-of-type {
      width: 130px;
      font-weight: 500;
      color: $grey-4;
    }

    & > p:last-of-type {
      color: $grey-1;

      & > span {
        color: $grey-3;
      }
    }
  }
}

.form {
  &__note {
    margin: 40px 0 24px;
  }

  &__cancelButton {
    width: 124px !important;
  }

  &__submitButton {
    width: 82px !important;
  }
}

.message {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;

  & > p {
    margin: 0;
    width: 100%;
    text-align: right;
    padding: 12px 16px;
    background-color: $white;
    border: 1px solid $grey-5;
    border-radius: 0px 0px 4px 16px;
    color: $grey-1;
  }

  &__alert {
    display: flex;
    flex-direction: row-reverse;
    gap: 9px;
    margin-top: 9px;

    & > svg {
      width: 24px;
      height: 24px;
    }

    & > span {
      display: block;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: $error;
    }
  }

  &--mine > & {
    &__alert {
      flex-direction: row;
    }
  }

  &--mine {
    flex-direction: row;

    & > p {
      border: none;
      background-color: $grey-1;
      border: 1px solid $grey-1;
      border-radius: 0px 0px 16px 4px;
      color: $white;
      text-align: left;
    }
  }

  &--blocked > p {
    color: $white !important;
    border-color: $error !important;
    background-color: $error !important;
  }
}

.print_template {
  width: 800px;
  border: 2px solid blue;
  &__info_label {
    font-weight: 500;
    font-size: 18px;
  }
  &__info_value {
    font-weight: 400;
    font-size: 16px;
    color: $grey-3;
  }

  &__image {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 700px;
    height: 800px;
    border: 2px solid $grey-5;
    border-radius: 27px 27px 4px 27px;
    box-shadow: 10px 5px $grey-5;

    & > img {
      max-width: 600px;
      max-height: 700px;
      object-fit: contain;
    }
  }
}
