@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  width: 100vw;
  height: 100vh;
}

body {
  margin: 0;
  width: 100%;
  height: 100%;
  font-family: 'DM Sans', -apple-system, 'Roboto', BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell',
  'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
}

textarea,
input {
  font-family: 'DM Sans', -apple-system, 'Roboto', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

scrollable_container {
  &::-webkit-scrollbar {
    height: 4px;
    width: 4px;
    background: transparent;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #9191a1;
    border-radius: 15px;
    transform: translateX(-40px);
  }
}




