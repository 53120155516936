@import 'vars.scss';

.wrapper {
  height: 100%;
  padding-top: 8px;
  padding-right: 5px;
  box-sizing: border-box;
}

.header {
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.list {
  box-sizing: border-box;
  height: calc(100% - 42px);
  overflow: hidden auto;
  @include scrollbar;
}

.staff {
  transition: 0.3s;
  cursor: pointer;
  margin-bottom: 8px;
  padding: 10px 20px;
  border-radius: 24px;
  border: 1px solid $grey-5;

  &:hover {
    background-color: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.3),
        rgba(255, 255, 255, 0.3)
      ),
      linear-gradient(97.42deg, $grey-6 6.15%, $grey-6 96.09%);
    box-shadow: 0px 6px 13px rgba(228, 228, 228, 0.7);
  }

  &__name {
    margin: 0;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    color: $grey-1;
  }

  &__email {
    margin: 0;
    font-size: 14px;
    line-height: 22px;
    color: $grey-1;
  }

  &__label {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: $grey-4;
  }
}

.checkbox {
  @at-root span#{&} {
    margin-right: 16px;
    padding: 0;
    font-size: 16px;
  }

  & > svg {
    font-size: 1em;
  }
}

.form {
  cursor: pointer;
  box-sizing: border-box;
  padding: 13px 16px;
  border: 1px solid $grey-5;
  border-radius: 16px;
  background-color: $white;

  &:not(:last-of-type) {
    margin-bottom: 8px;
  }

  & > div {
    font-size: 16px;
    line-height: 24px;
    color: $grey-1;
  }

  & > div:last-of-type {
    color: $grey-4;
    text-align: end;
  }

  &__id {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $grey-1;
  }

  &__inmate {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;

    &__avatar {
      @at-root div#{&} {
        margin-right: 16px;
      }
    }

    & > div:last-of-type {
      width: calc(100% - 56px);
    }

    &__name {
      margin: 0;
      width: 100%;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: $grey-1;
    }

    &__id {
      margin: 0;
      font-size: 14px;
      line-height: 22px;
      color: $grey-4;
    }
  }

  &__status {
    padding: 1px 12px;
    border-radius: 12px;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: $white;

    @include formStatusesColor();
  }

  &__menuButton {
    @at-root button#{&} {
      margin-left: 10px;
      padding: 0;
    }
  }

  &__menu {
    @at-root ul#{&} {
      padding: 0;
    }

    &__wrapper {
      @at-root div#{&} {
        width: 120px;
        box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.06);
        border-radius: 8px;
      }
    }

    &__item {
      @at-root li#{&} {
        padding: 13px 16px;
        font-size: 14px;
        line-height: 22px;
        color: $grey-1;
      }

      &:hover {
        background-color: $grey-6;
      }
    }
  }
}
