@import 'vars.scss';

.wrapper {
  box-sizing: border-box;
  margin: 0 auto !important;
  height: calc(100% - 75px);
  max-width: 90%;
  padding-top: 20px;
  padding-bottom: 20px;
}

.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.subheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 17px;
  width: 100%;
  padding: 20px 0;
  border-bottom: 2px solid $grey-6;
}

.form {
  box-sizing: border-box;
  height: calc(100% - 47px);
}

div.listHeader {
  padding: 0 16px 17px 16px;

  & > div {
    font-size: 14px;
    line-height: 22px;
    color: $grey-4;
    white-space: nowrap;
  }

  & > div:last-of-type {
    padding-right: 40px;
    text-align: end;
  }
}

.list {
  height: calc(100% - 76px - 56px);
  overflow: scroll;
}

.checkbox {
  @at-root span#{&} {
    margin-right: 16px;
    padding: 0;
    font-size: 16px;
  }

  & > svg {
    font-size: 1em;
  }
}

.survey {
  cursor: pointer;
  position: relative;
  padding: 16px;
  border-radius: 8px;
  transition: 0.3s;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0em;

  &:hover {
    background-color: $grey-6;
  }

  &::after {
    content: '';
    display: block;
    width: calc(100% - 32px);
    height: 1px;
    position: absolute;
    left: 16px;
    bottom: 0;
    background-color: $grey-6;
  }

  & p {
    margin: 0;
  }

  &__name {
    display: flex;
    align-items: center;
    & > p {
      max-width: 85%;
      @include textEllipsis();
    }

    color: $grey-1;
  }
  &__recipients {
    display: flex;
    align-items: center;
    color: $grey-4;

    & > p {
      max-width: 85%;
      @include textEllipsis();
    }
    & > svg {
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }
  }

  &__statistic {
    display: flex;
    // justify-content: center;
    align-items: center;
    padding-left: 30px;
    color: $grey-4;

    & > div {
      height: 10px;
      width: 1px;
      border-radius: 99px;
      margin: 0 6px;
      background-color: $grey-5;
    }
  }

  &__sentiment {
    display: flex;
    align-items: center;
    & > div {
      background-color: $grey-3;
      border-radius: 50%;
      width: 12px;
      height: 12px;
    }
  }
  &__date {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    & > p {
      color: $grey-4;
    }
  }

  &__menuButton {
    @at-root button#{&} {
      margin-left: 10px;
      padding: 0;
    }
  }

  &__menu {
    @at-root ul#{&} {
      padding: 0;
    }

    &__wrapper {
      @at-root div#{&} {
        box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.06);
        border-radius: 8px;
      }
    }

    &__item {
      @at-root li#{&} {
        padding: 13px 16px;
        font-size: 14px;
        line-height: 22px;
        color: $grey-1;
      }

      &:hover {
        background-color: $grey-6;
      }
    }
  }
}
