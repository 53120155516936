@import 'vars.scss';

.questions {
  &__add {
    @include iconButton(true);

    @at-root button#{&} {
      padding: 12px;
      font-size: 16px;
      letter-spacing: 0;
      font-weight: 500;
      line-height: 0;
      text-transform: capitalize;
      color: $grey-1;
    }
  }
}

.question {
  position: relative;
  margin-bottom: 60px;

  &::after {
    content: '';
    position: absolute;
    bottom: -40px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: $grey-5;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  &__headerLeft {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  &__removeBtn {
    @include iconButton();
    @at-root button#{&} {
      width: 48px;
      height: 48px;
      & > svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  &__select {
    @at-root div#{&} {
      margin: 0;
      width: 240px;
    }
  }

  &__number {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    border-radius: 8px;
    background: $grey-5;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    color: $grey-1;
  }

  &__name {
    margin-bottom: 16px;
    width: 100%;
  }
}

.input {
  display: grid;
  & > input {
    padding: 12px 16px;
    border: 1px solid $grey-5;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: $grey-1;
    outline: none;

    &::placeholder {
      color: $grey-4;
    }
  }
}

.multipleChoiceInput {
  position: relative;
  max-width: 100%;
  min-width: 103px;

  &:hover &__btn {
    display: flex;
  }
  & > span,
  input {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  & > span {
    position: absolute;
    opacity: 0;
  }

  & > input {
    width: calc(100% - 34px);
    min-width: 70px;
    padding: 12px 16px;
    border: 1px solid $grey-5;
    border-radius: 8px;
    color: $grey-1;
    outline: none;

    &::placeholder {
      width: 74px;
      color: $grey-4;
    }
  }

  &__btn {
    @at-root button#{&} {
      display: none;
      position: absolute;
      top: 50%;
      right: -12px;
      width: 24px;
      height: 24px;
      background: $grey-5;

      transform: translateY(-50%);
      & > svg {
        width: 16px;
        height: 16px;
      }
    }
  }
}

.choiceInput {
  position: relative;
  width: fit-content;

  &:hover &__btn {
    display: flex;
  }

  &__btn {
    @at-root button#{&} {
      display: none;
      position: absolute;
      top: 50%;
      right: -12px;
      width: 24px;
      height: 24px;
      background: $grey-5;

      transform: translateY(-50%);
      & > svg {
        width: 16px;
        height: 16px;
      }
    }
  }
}

.addChoice {
  @include iconButton(false);
  @at-root button#{&} {
    width: 135px;
    line-height: 0;
    text-transform: capitalize;
    color: $grey-1;
    &:disabled {
      & > span {
        opacity: 0.2;
      }
    }
  }
}

.radio {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;
  &__input {
    width: 240px;
  }
}

.multiple {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 16px;
}

.fakeAnswerInput {
  display: grid;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;

  & > label {
    display: block;
    margin-bottom: 8px;
  }

  & > textarea {
    padding: 12px 16px;
    height: 114px;
    resize: none;
    outline: none;
    border: 1px solid $grey-5;
    border-radius: 12px;

    &::placeholder {
      color: $grey-4;
    }
  }
}

.yesNo {
  display: flex;
  gap: 16px;

  &__option {
    cursor: default;
    padding: 12px 20px;
    border: 1px solid $grey-5;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: $grey-1;
  }
}

.ratingQuestion {
  display: flex;
  justify-content: space-between;
  &__options {
    display: flex;
    gap: 16px;
  }
  &__option {
    display: grid;
    place-items: center;
    height: 46px;
    width: 46px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    border: 1px solid $grey-5;
    border-radius: 8px;
  }

  &__select {
    @at-root div#{&} {
      margin: 0;
      width: 88px;
    }
  }
}
