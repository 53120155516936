@import 'vars.scss';

.step {
  &__icon {
    box-sizing: border-box;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 2px solid $grey-4;
    padding: 5px;
    margin-right: 10px;

    &--active {
      border: 2px solid $grey-1;
    }

    &__number {
      margin: 0;
      font-weight: 700;
      font-size: 12px;
      line-height: 20px;
      color: $grey-4;
    }

    &--active > &__number {
      color: $grey-1;
    }

    &--completed {
      padding: 4px;
    }
  }

  &__title {
    margin: 0;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    color: $grey-4;

    &--active {
      color: $grey-1;
    }
  }
}
