.input__type__horizontal {
  position: relative;
  width: 100%;
  height: 20px;
  background: #ebebef;
  border-radius: 8px;

  &__background {
    background-size: 100%;
    height: 100%;
    width: 0%;
    background: #ebebef;
    background-image: linear-gradient(#038768, #038768);
    border-radius: 8px;
    transition: width 1s;
  }
}

.input__type__vertical {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  width: 50px;
  height: 120px;
  background: #ebebef;
  border-radius: 8px;

  &__background {
    width: 100%;
    height: 0%;
    background: linear-gradient(#038768, #038768);
    background-image: #ebebef;
    border-radius: 8px;
    margin: 0;
    transition: height 1s;
  }
}
