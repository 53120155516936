@import 'vars.scss';

.header {
  margin-top: 3px;
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__title {
    margin: 0;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: $grey-1;
  }

  &__closeIcon {
    @at-root svg#{&} {
      cursor: pointer;
      width: 24px;
      height: 24px;
    }
  }
}

.input {
  width: calc(100% - 32px);
  padding: 0;
  font-size: 14px;
  line-height: 22px;
  border: none;
  outline: none;
  color: $grey-1;
  background-color: $white;

  &::placeholder {
    color: $grey-4;
  }

  &__wrapper {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    height: 34px;
    min-width: 100%;
    padding: 10px 8px;
    border-radius: 8px;
    border: 1px solid $grey-5;
    background-color: $white;
  }

  &__icon {
    @at-root svg#{&} {
      width: 16px;
      height: 16px;
      margin: 0 8px 0 0;

      & > path {
        stroke: $grey-1;
      }
    }
  }
}

.results {
  &__text {
    margin: 0;
    font-size: 14px;
    line-height: 22px;
    color: $grey-4;
  }

  &__list {
    box-sizing: border-box;
    height: calc(100% - 148px);
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    overflow: hidden auto;
    @include scrollbar;
  }
}

.message {
  box-sizing: border-box;
  display: flex;
  cursor: pointer;
  transition: 0.3s;
  width: 100%;
  padding: 8px;
  border-radius: 16px;

  &:hover {
    background-color: $grey-6;
  }

  &:not(:last-of-type) {
    margin-bottom: 4px;
  }

  &__avatar {
    @at-root div#{&} {
      margin-right: 8px;
    }
  }

  &__info {
    width: calc(100% - 48px);

    & p {
      margin: 0;
    }

    & > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }

    &__author {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: $grey-1;
    }

    &__date {
      font-size: 12px;
      line-height: 20px;
      color: $grey-4;
    }
  }

  &__text {
    width: 100%;
    font-size: 14px;
    line-height: 22px;
    color: $grey-4;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__image {
    margin-top: 8px;
    width: 44px;
    height: 44px;
    object-fit: cover;
    border-radius: 8px;
  }
}
