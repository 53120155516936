@import 'vars.scss';

.file {
  display: grid;
  width: 205px;
  height: 285px;
  border: 1px solid $grey-5;
  border-radius: 8px;
  padding-bottom:8px;


  &__content {
    display: grid;
    padding: 22px 16px;
    border-bottom: 1px solid $grey-5;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;

    & > button {
      padding: 0;
    }
  }

  &__icon {
    @at-root svg#{&} {
      margin: 0 auto;
      width: 60px;
      height: 60px;
      color: $info;

      &--Image {
        color: $info;
      }

      &--Video {
        color: $warning;
      }

      &--File {
        color: $brand-green-2;
      }
    }
  }

  &__footer {
    display: grid;
    gap: 8px;
    padding: 8px 16px;
  }

  &__name {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: $grey-1;

    @include textEllipsis();
  }

  &__date {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: $grey-4;

  }
}

.filesList {
  &__title {
    margin: 0;
    margin-bottom: 20px;
    font-size: 16px;
    letter-spacing: 0;
    font-weight: 500;
    line-height: 24px;
    color: $grey-1;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    height: 100%;
  }
}

.content {
  background-color: transparent;

  div {
    padding: 6px 0 12px 0;
  }

  span {
    display: block;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: $grey-1;
  }

  &__size,
  &__creationDate {
    color: $grey-4;
  }

  &__status {
    &--Displayed,
    &--Hidden {
      display: inline-flex;
      margin: 10px 4px 0 4px;
      padding: 1px 12px !important;
      border-radius: 12px;
      color: #ffffff;
      background: #03a882;
    }

    &--Hidden {
      background: #df093c;
    }
  }

  & .recipient__item {
    display: inline-flex;
    margin: 10px 4px 0 4px;
    padding: 1px 12px;
    background: #ebebef;
    border-radius: 12px;
  }
}

.filePreview {
  display: grid;
  justify-content: center;
}

.deleteModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  height: fit-content;
  width: fit-content;
  max-width: 500px;

  &__modalHeader {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: flex-start;
  }

  &__modalTitle {
    display: flex;
    flex-direction: row;
    align-content: center;
    text-align: center;
    padding-bottom: 0px;
    padding-left: 12px;
    padding-top: 3px;
  }

  &__formLoader {
    display: flex;
    justify-content: center;
  }

  &__modalButtons {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15%
  }

  &__fileDisplayName {
    font-size: 20px;
    font-weight: 500;
  }

  // &__backButton{

  // }
}

.inmateViewedModal {
  width: 40%;
  min-width: 510px;
  min-height: 300px;
  max-height: 60%;
  display: flex;
  flex-direction: column;
  // align-items: center;
  &:last-child {
    align-items: center;
  }

  &__header {
    padding-bottom: 6px;
  }

  &__input {
    font-size: 16px;
    line-height: 24px;
  }
}

.inmateCards {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 30%;
  margin-top: 10px;
  overflow-y: auto;
  @include scrollbar;

  &__divider {
    border-radius: 2px;
    width: 97%;
    height: 2px;
    background-color: $grey-5;
    margin-bottom: 12px;
  }

  &__item {
    box-sizing: border-box;
    // cursor: pointer;
    display: flex;
    // flex-direction: column;
    justify-content: left;
    margin-bottom: 8px;
    width: 90%;
    height: 72px;
    padding: 15px;
    border-radius: 16px;
    border: 1px solid $grey-5;
    background-color: $white;
    align-items: center;
    transition: 0.3s;

    &__ownerName {
      margin-left: 7%;
      display: flex;
      flex-direction: column;

      &__ownerId {
        font-size: 11px;
      }

    }

    &__dateRead {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-left: auto;
    }
  }


}

