@import 'vars.scss';

.wrapper {
  box-sizing: border-box;
  width: 100%;
  height: 324px;
  padding: 16px 12px;
  border: 1px solid $grey-5;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden auto;

  @include scrollbar;

  & > div {
    margin-bottom: 16px;
  }
}

.input {
  font-size: 16px;
  line-height: 24px;
}

.checkbox {
  & svg {
    font-size: 1em !important;
  }

  @at-root span#{&} {
    padding: 0;
    margin-right: 8px;
    font-size: 20px;
  }

  &__label {
    @at-root label#{&} {
      margin: 0;

      &:not(:last-of-type) {
        margin-bottom: 16px !important;
      }
    }

    & > span:last-of-type {
      font-size: 16px;
      line-height: 24px;
      color: $grey-1;
    }
  }
}

.result {
  box-sizing: border-box;
  width: 100%;
  height: 48px;
  padding: 12px 4px 4px 12px;
  border: 1px solid $grey-5;
  border-radius: 8px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  overflow: hidden auto;
  @include scrollbar;

  &__placeholder {
    margin: -8px 0 0 0;
    font-size: 16px;
    line-height: 24px;
    color: $grey-4;
  }

  &__item {
    @at-root div#{&} {
      height: 22px;
      margin: 0 8px 8px 0;
      background: transparent;
      border: 1px solid $grey-4;
      border-radius: 12px;
    }

    &__label {
      padding: 0 6px 0 8px !important;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      color: $grey-1;
    }

    &__deleteIcon {
      font-size: 12px !important;
      margin: 0 8px 0 0 !important;
      color: $grey-4 !important;
    }
  }
}
