@import 'vars.scss';

.wrapper {
  box-sizing: border-box;
  height: 100%;
  width: calc(100% + 10px);
}

.content {
  box-sizing: border-box;
  margin-left: -10px;
  padding: 0 6px 0 10px;
  height: calc(100% - 74px);
  overflow: hidden auto;
  @include scrollbar;
}

div.header {
  margin: 20px 0;
  padding-right: 10px;
}

.sorting {
  margin: 0 16px 0 0 !important;

  &:hover {
    background: none !important;
  }

  &__label {
    display: block;
    margin-right: 8px;
    color: $grey-4;
    font-size: 14px;
    line-height: 22px;
  }

  &__input {
    background-color: transparent !important;
    font-weight: 500 !important;

    & > div {
      background-color: transparent !important;
    }
  }

  &__selectedItem {
    background-color: rgba(0, 0, 0, 0.08) !important;
  }

  &__icon {
    & > path {
      fill: $grey-1;
    }
  }

  & > div {
    border-bottom: none;

    &::before,
    &::after {
      display: none !important;
    }
  }
}

button.blockButton {
  padding: 5px 20px 5px 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
}

.chat {
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 13px 24px 13px 16px;
  border-radius: 16px;
  border: 1px solid $grey-5;
  background-color: $white;
  transition: 0.3s;

  &--blocked {
    background-color: $grey-6;
  }

  &:hover {
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.06);
  }

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  &__avatar {
    margin: 0 16px 0 0;
  }

  &__leftInfo {
    & > p {
      margin: 0;
      font-size: 14px;
      line-height: 22px;
    }

    & > p:first-of-type {
      width: fit-content;
      font-weight: 500;
      color: $grey-1;

      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }

    & > p:last-of-type {
      color: $grey-4;
    }
  }

  &__rightInfo {
    & > p {
      margin: 0;
      font-size: 14px;
      line-height: 22px;
      text-align: right;
    }

    & > p:first-of-type {
      color: $grey-4;
    }

    & > p:last-of-type {
      color: $grey-1;
    }
  }

  &__lockIcon {
    @at-root svg#{&} {
      color: $grey-1;
      margin-left: 16px;
      width: 24px;
      height: 24px;
    }
  }
}

.chatList {
  height: calc(100vh - 295px);
  overflow: hidden auto;
  @include scrollbar;
}
