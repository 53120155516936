@import 'vars.scss';

.zohoForm {
  padding: 40px;
  border-radius: 16px;
  background: $brand-green-1;

  &__container {
    display: flex;
    flex-direction: column;
    max-width: 784px;
    margin: 0 auto;
  }

  &__title {
    margin-bottom: 16px;
    font-size: 24px;
    letter-spacing: 0;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
    color: $white;
  }

  &__info {
    margin-bottom: 24px;
    font-size: 16px;
    letter-spacing: 0;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: $white;
  }
}

.form {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &__grid {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr;
  }
}

.submitButton {
  @at-root button#{&} {
    margin-left: auto;
    width: 180px;
    height: 48px;
    padding: 12px 20px 12px 20px;
    border: 1px solid $grey-5;
    border-radius: 8px;
    border-radius: 8px;
    background: $white;
    font-size: 16px;
    letter-spacing: 0;
    font-weight: 500;
    line-height: 24px;
    text-transform: capitalize;
    &:not(:disabled) {
      cursor: pointer;
    }
  }
}

.textInput {
  display: flex;
  flex-direction: column;

  & > textarea,
  input {
    padding: 11px 16px;
    border: 1px solid $grey-5;
    border-radius: 8px;
    background: $white;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: $grey-1;

    outline: none;
    &::placeholder {
      color: $grey-4;
    }
  }

  &--error {
    & > input,
    textarea {
      border: 1px solid $error;
    }
  }

  & > textarea {
    height: 116px;
    resize: none;
    
    @include scrollbar();
  }

  & > label {
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    color: $white;
  }
}
