@import 'vars.scss';

.wrapper {
  box-sizing: border-box;
  margin: 0 auto !important;
  height: calc(100% - 75px);
  max-width: 90%;
  padding-top: 20px;
  padding-bottom: 20px;
}

.content {
  padding: 16px 24px 12px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto hidden;
  border-radius: 16px;
  border: 1px solid $grey-5;
  background-color: $white;
  box-shadow: 0px 6px 13px rgba(228, 228, 228, 0.7);

  @include scrollbar;
}

.tabs {
  border-bottom: 2px solid $grey-6;

  &__indicator {
    @at-root span#{&} {
      background-color: $grey-1;
      min-width: unset;
    }
  }

  &__item {
    @at-root button#{&} {
      font-size: 16px;
      text-transform: none;
      line-height: 22px;
      padding: 4px 0;
      margin-right: 34px;
      text-align: left;
      min-width: unset;
    }
    &#{&}--active {
      @at-root button#{&} {
        color: $grey-1;
      }
    }
  }

  &__content {
    flex-grow: 1;
    padding: 8px 0 0 0;
    height: calc(100% - 10px);
  }
}
