.modal {
  width: 584px;
}

.form {
  padding: 16px 0 0 0;

  &__switcher {
    margin-top: 16px;
  }

  &__note {
    margin-top: 40px;
  }

  &__submit {
    margin-top: 32px;
  }
}

.button:not(:last-of-type) {
  margin-right: 16px;
}

.button:first-of-type {
  width: 124px;
}
