@import 'vars.scss';

.singleRecipient{
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
}
  
.accordion{
    width: 100%;
    max-height: 151px;
    overflow: auto;
    background-color: $grey-6 !important;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;

    @include scrollbar
}
  