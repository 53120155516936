@import 'vars.scss';

button.button {
  border-radius: 12px;
  padding: 5px 16px;
  margin: 15px 15px 0 15px;
  text-transform: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: $grey-1;
  margin-right: 8px;
}

.modal {
  @at-root div#{&} {
    min-width: unset;
    width: 468px;
    padding: 24px;
  }

  &__title {
    margin-bottom: 24px !important;
  }

  &__subtitle {
    margin: 0 0 8px 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $grey-1;
  }
}

div.input {
  width: 100%;
  margin-bottom: 16px;
}

.checkbox {
  @at-root span#{&} {
    width: 18px;
    height: 18px;
    padding: 0;
    margin-right: 4px;
  }

  & > svg {
    width: 100%;
    height: 100%;
  }

  &__wrapper {
    @at-root label#{&} {
      margin: 26px 0 24px 0;
    }
  }

  &__label {
    @at-root span#{&} {
      font-size: 16px;
      line-height: 24px;
    }
  }
}

button.cancelButton {
  width: 124px;
  margin-right: 16px;
}
