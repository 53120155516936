@import 'vars.scss';

.notification {
  @at-root div#{&} {
    cursor: default;
    display: flex;
    align-items: center;
    justify-content: stretch;
    width: 500px;
    border-radius: 16px;
    padding: 16px 24px;
  }

  & > div {
    margin: 0;
    padding: 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
  }

  & div {
    font-family: 'DM Sans', -apple-system, 'Roboto', sans-serif;
  }

  &__closeButton {
    cursor: pointer;
    background: transparent;
    border: none;
    padding: 0;
    width: 24px;
    height: 24px;

    &__icon {
      width: 100%;
      height: 100%;
    }
  }

  &__progress {
    visibility: hidden;
  }
}

:global(div.Toastify__toast--success) {
  background-color: $success;
  color: $white;
}
:global(div.Toastify__toast--info) {
  background-color: $grey-1;
  color: $white;
}

:global(div.Toastify__toast--error) {
  background-color: $error;
  color: $white;
}

:global(div.Toastify__toast-icon) {
  display: none;
}
