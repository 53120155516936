@import 'vars.scss';

.select {
  width: 100%;
  margin: 8px 0;
  display: block !important;

  &:hover {
    background: none !important;
  }

  &__label {
    display: block;
    padding: 12px 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: $grey-1;

    & > sup {
      color: $error;
      font-size: 14px;
      font-weight: 400;
    }
  }

  &__input {
    background-color: transparent !important;
    font-weight: 500 !important;
    border-radius: 8px !important;

    & > fieldset,
    &:hover > fieldset,
    &:active > fieldset {
      border: 1px solid $grey-5 !important;
    }

    & > div {
      box-sizing: border-box;
      padding: 12px 8px 12px 16px;
      border: none !important;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
    }

    &--placeholder > div {
      color: $grey-4 !important;
    }
  }

  &__options {
    border-radius: 12px !important;
    transform: translateY(4px) !important;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.06);

    & > ul {
      padding: 0;

      & > li {
        padding: 13px 16px;
        font-size: 16px;
        line-height: 22px;

        &:hover {
          background-color: $grey-6;
        }
      }
    }
  }

  &__selectedItem {
    position: relative;
    background-color: $white !important;
    
    & > svg {
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%);
      width: 16px;
      height: 16px;
    }
  }

  &__icon {
    & > path {
      fill: $grey-1;
    }
  }
}
