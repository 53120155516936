@import 'vars.scss';

button.button {
  padding: 10px 22px 10px 20px;
  border-radius: 12px;
  text-transform: none;
  font-size: 16px;
  line-height: 24px;
  white-space: nowrap;
  overflow: hidden;

  &:not(:last-of-type) {
    margin-right: 12px;
  }

  & > span {
    border-radius: unset;
  }

  &--primary {
    background-color: $grey-1;
    border: 1px solid;
    border-color: $grey-1;
    color: $white;

    & svg > path {
      fill: $white;
    }

    & svg > rect {
      stroke: $white;
    }

    &:hover {
      background-color: transparent;
      color: $grey-1;

      & svg > path {
        fill: $grey-1;
      }

      & svg > rect {
        stroke: $grey-1;
      }
    }

    &:disabled {
      color: $white;
      background-color: $grey-4;
      border-color: $grey-4;
    }
  }

  &--secondary {
    background-color: transparent;
    border: 1px solid $grey-5;
    color: $grey-1;

    & svg > path {
      fill: $grey-1;
    }
  }
}
