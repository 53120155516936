h1 {

  font-weight: 700;
  font-size: 44px;
  line-height: 32px;
  text-align: left;

}

h3 {
  font-weight: 500;
  font-size: 26px;
  line-height: 24px;
  color: #686873;
  text-align: left;
}

.info_wrapper {
  display: inline-block;
  width: 50%;
  position: absolute;
  top: 50%;
  left: 30%;
  transform: translate(-50%, -50%);
  z-index: 4;
}

.image_wrapper {
  display: flex;
  width: 50%;
  position: absolute;
  justify-content: center;
  top: 55%;
  right: 0%;
  transform: translate(0%, -55%);

  image {
    z-index: 0;
  }

}

.stack_info {
  border-radius: 8px;
  text-align: left;
  margin-bottom: 30px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

}


.action_wrapper {
  text-align: left;
}

.button_action {
  background: black;
  border: 1px solid  black;
  border-radius: 12px;
  padding: 12px 20px;
  width: 198px;
  height: 48px;
  margin: 10px 10px 0 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;

  &:hover {
    background: white;
    color:black ;
  }
}