@import 'vars.scss';

.wrapper {
  position: relative;
}

.autocomplete {
  &--focused &__input__outline {
    border-width: 1px !important;
  }

  & &__input {
    border-radius: 8px;
    padding: 12px 15px 12px 13px !important;
  }

  &__input input {
    padding: 0 !important;
    height: 100%;
  }
}

.popper {
  margin: 0;
  box-sizing: border-box;
  padding: 8px 12px 12px 12px;
  width: 100%;
  height: 350px;
  z-index: 1;
  position: absolute;
  top: 60px;
  left: 0;
  background-color: $white;
  border: 1px solid $grey-5;
  border-radius: 7px;
  box-shadow: 0px 6px 13px rgba(184, 184, 184, 0.7);

  &--hidden {
    display: none !important;
  }

  & > div > ul {
    height: 300px;
    @include scrollbar;
  }
}

.inmate {
  cursor: pointer;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
  padding: 8px;

  &:hover {
    background-color: $grey-6;
  }

  &:not(:last-child) {
    margin-bottom: 4px;
  }

  & > div {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }

  &__avatar {
    @at-root div#{&} {
      width: 32px !important;
      height: 32px !important;
      margin-right: 16px;
    }
  }

  &__name {
    margin-left: 16px;
  }

  &__id {
    margin: 0;
    font-size: 16px;
    line-height: 24px;
  }

  &__id {
    color: $grey-3;
  }
}

.result {
  margin: 16px -8px -8px 0;
  height: 132px;
  border-radius: 8px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;

  &--empty {
    margin: 16px 0 0 0;
    height: 124px;
    background: $grey-6;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  &__icon {
    @at-root svg#{&} {
      width: 40px;
      height: 40px;
    }
  }

  &__text {
    margin: 4px 0 0 0;
    color: $grey-3;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
  }

  &__subtext {
    margin: 0;
    font-size: 12px;
    line-height: 20px;
    color: $grey-4;
  }

  &__item {
    @at-root div#{&} {
      height: 30px;
      margin: 0 8px 8px 0;
      background: $grey-5;
      border-radius: 12px;
    }

    &__label {
      padding: 0 6px 0 12px !important;
      font-size: 14px;
      line-height: 22px;
      color: $grey-1;
    }

    &__deleteIcon {
      font-size: 12px !important;
      margin: 0 10px 0 0 !important;
    }
  }
}
