@import 'vars.scss';

.modal {
  padding: 24px 32px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: $white;
  border-radius: 24px;
  // min-width: 550px;
  max-width: 800px;
  width: 50%;
  box-sizing: border-box;
  outline: none;

  &__title {
    margin: 0 0 8px 0;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    color: $grey-1;
    white-space: pre-wrap;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 24px;
  }

  &__description {
    color: $grey-4;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    padding-top: 24px;
  }
}
