@import 'vars.scss';

.roles_section {
  box-sizing: border-box;

  height: calc(85vh - 50px);

  border: 1px solid $grey-5;
  border-radius: 16px;

  overflow-y: hidden;

  h3 {
    margin-left: 10px;
  }
}
