@import 'vars.scss';

.wrapper {
  box-sizing: border-box;
  margin: 0 auto !important;
  height: 100%;
  max-width: 1105px;
  padding-top: 15px;
}

.wrapperForm {
  box-sizing: border-box;
  height: calc(100% - 47px);
}

.checkbox {
  @at-root span#{&} {
    margin-right: 16px;
    padding: 0;
    font-size: 16px;
  }

  & > svg {
    font-size: 1em;
  }
}
