.modal {
  width: 584px;
}

.note {
  margin: 40px 0 32px 0;
}

.button:not(:last-of-type) {
  margin-right: 16px;
}

.button:first-of-type {
  width: 124px;
}
