@import 'vars.scss';

.select {
  width: 100%;
  margin: 8px 0;

  &:hover {
    background: none !important;
  }

  &__label {
    display: block;
    margin-right: 8px;
    color: $grey-3;
    font-size: 14px;
    line-height: 22px;
  }

  &__input {
    background-color: transparent !important;
    font-weight: 500 !important;

    & > div {
      background-color: transparent !important;
    }
  }

  &__selectedItem {
    background-color: rgba(0, 0, 0, 0.08) !important;
  }

  &__icon {
    & > path {
      fill: $grey-1;
    }
  }

  & > div {
    border-bottom: none;

    &::before,
    &::after {
      display: none !important;
    }
  }
}
