@import 'vars.scss';

.wrapper {
  padding-top: 8px;
  height: 90%;
  overflow: auto;
  @include scrollbar;
}

.role {
  cursor: pointer;
  transition: 0.3s;
  // width: 80%;

  padding: 19px 13px 19px 20px;

  &:not(:last-of-type) {
    margin-bottom: 0px;
  }

  &:hover {
    background-color: $grey-5;
    box-shadow: 0px 6px 13px rgba(228, 228, 228, 0.7);
  }

  &__title {
    margin: 0;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: $grey-1;
  }

  &__staff {
    margin: 0;
    font-size: 14px;
    line-height: 22px;
  }
  &__open {
    @at-root svg#{&} {
      display: none;
      width: 24px;
      height: 24px;
      margin-left: 16px;

      & path {
        fill: $grey-4;
      }
    }
  }
}

button.button {
  float: right;
  padding: 5px 20px 5px 12px;
  margin-bottom: 8px;
  border-radius: 8px;
  overflow: hidden;
  text-transform: none;
  font-size: 16px;
  line-height: 24px;
  white-space: nowrap;
  color: $grey-1;
  border: none;
  transform: translateX(15px);

  & > span:last-of-type {
    border-radius: unset;
  }
}

button.cancelButton {
  width: 124px;
  margin-right: 16px;
}

button.createButton {
  float: right;
  padding: 5px 20px 5px 12px;
  margin-bottom: 8px;
  border-radius: 8px;
  overflow: hidden;
  text-transform: none;
  font-size: 16px;
  line-height: 24px;
  white-space: nowrap;
  color: $grey-1;
  border: none;
  transform: translateX(15px);

  & > span:last-of-type {
    border-radius: unset;
  }
}

.title {
  margin: 15px 32px 0px 32px !important;
  padding: 0;
  height: 5%;
}

.modal {
  height: 80%;
  width: 600px !important;
  padding: 15px 0 !important;
}

.form {
  display: flex;
  flex-direction: column;
  height: calc(100% - 52px);

  &__content {
    padding: 20px 32px 0 32px;
    height: calc(100% - 70px);
    overflow: auto;

    @include scrollbar;
  }

  &__input {
    margin-bottom: 20px;
  }

  &__subtitle {
    margin: 20px 0 16px 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $grey-1;
  }

  &__permissions {
    flex-grow: 1;
    @include scrollbar;
  }

  &__actions {
    display: flex;
    padding: 24px 24px 10px 24px;
    box-shadow: 0px -7px 16px rgba(0, 0, 0, 0.05);
  }
}
