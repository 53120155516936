.answer__type_option {
  position: relative;

  &__response {
    & > span {
      font-family: 'DM Sans';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      /* identical to box height, or 157% */

      display: flex;
      align-items: center;

      /* Grey/Grey 1 */

      color: #101010;
    }
  }

  &__responses_number {
    & > span {
      font-family: 'DM Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      /* identical to box height, or 157% */

      display: flex;
      // align-items: center;
      justify-content: flex-end;

      /* Grey/Grey 4 v.2 */

      color: #9191a1;
    }
  }

  &__response_percent {
    & > span {
      font-family: 'DM Sans';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      /* identical to box height, or 157% */

      align-items: center;

      /* Grey/Grey 1 */

      color: #101010;
    }
  }
}

.answer__type_rating {
  &__responses_number {
    & > span {
      font-family: 'DM Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      /* identical to box height, or 157% */

      display: flex;
      align-items: center;
      text-align: right;

      /* Grey/Grey 4 v.2 */

      color: #9191a1;
    }
  }

  &__response_percent {
    & > span {
      font-family: 'DM Sans';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      /* identical to box height, or 157% */
      align-items: center;
      /* Grey/Grey 1 */

      color: #101010;
    }
  }
}
