.audio_player__wrapper {
  #audio-player-progress-bar:focus {
    box-shadow: 0 0 3px 1px #038768;
    outline: none;
  }

  #audio-player-progress-bar {
    height: 7px;
    width: 90%;
    background: #ffff;
    border-radius: 5px;
    background-image: linear-gradient(#038768, #038768);
    background-repeat: no-repeat;
    box-shadow: 0 0 2px 0 #038768;
  }

  /* SaveModalInput Thumb */
  #audio-player-progress-bar::-webkit-slider-thumb {
    // -webkit-appearance: none;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: #038768;
    cursor: ew-resize;
    box-shadow: 0 0 2px 0 #555;
  }

  #audio-player-progress-bar::-moz-range-thumb {
    // -webkit-appearance: none;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background: #038768;
    cursor: ew-resize;
    box-shadow: 0 0 2px 0 #555;
    transition: background 0.3s ease-in-out;
  }

  #audio-player-progress-bar::-ms-thumb {
    // -webkit-appearance: none;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background: #038768;
    cursor: ew-resize;
  }

  #audio-player-progress-bar::-webkit-slider-thumb:hover {
    background: #038768;
  }

  #audio-player-progress-bar::-moz-range-thumb:hover {
    background: #038768;
  }

  #audio-player-progress-bar::-ms-thumb:hover {
    background: #038768;
  }

  /* SaveModalInput Track */
  #audio-player-progress-bar::-webkit-slider-runnable-track {
    // -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
    transition: background 0.3s ease-in-out;
  }

  #audio-player-progress-bar::-moz-range-track {
    // -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
    transition: background 0.3s ease-in-out;
  }

  #audio-player-progress-bar::-ms-track {
    // -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }
}
