@import 'vars.scss';

.input {
  width: 82px;
  height: 50px;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  border-radius: 8px;
  border: 1px solid $grey-5;
  color: $grey-1;

  &:disabled {
    color: $grey-4;
  }

  &:focus {
    outline: none;
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }

  &__label,
  &__postLabel {
    display: block;
    font-size: 16px;
    line-height: 24px;
  }

  &__label {
    margin-bottom: 8px;
    font-weight: 500;
  }

  &__postLabel {
    margin-left: 12px;
  }

  &__wrapper {
    display: flex;
    align-items: center;
  }
}
