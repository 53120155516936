@import 'vars.scss';

.input {
  box-sizing: border-box;
  resize: none;
  width: 100%;
  height: 136px;
  font-size: 16px;
  line-height: 24px;
  padding: 10px;
  border-radius: 12px;
  border: 1px solid $grey-5;
  color: $grey-1;

  @include scrollbar;

  &::placeholder {
    color: $grey-4;
  }

  &:disabled {
    color: $grey-4;
  }

  &:focus {
    outline: none;
  }

  &__label {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: $grey-1;
  }

  &__helperText {
    display: inline-block;
    margin-left: 12px;
    font-weight: 400;
    color: $grey-4;
  }
}
