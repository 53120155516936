@import 'vars.scss';

.skeleton {
  width: 100%;
  padding: 18px 16px;
  border-radius: 24px;
  background-color: $grey-6;
  position: relative;

  @include loadingAnimation;

  &:not(:last-of-type) {
    margin-bottom: 8px;
  }

  &__circle {
    width: 40px;
    height: 40px;
    background-color: $grey-5;
    border-radius: 50%;
    margin-right: 13px;
  }

  &__lines {
    &__item {
      width: 125px;
      height: 12px;
      background-color: $grey-5;
      border-radius: 4px;

      &:not(:last-of-type) {
        margin-bottom: 10px;
      }

      &--short {
        width: 87px;
      }
    }
  }
}

.documentSkeleton{
  display: flex;
  flex-direction: column;
  contain: content;
  row-gap: 15px;
  align-items: center;
  justify-content: center;
  background-color: $grey-6;
  width: 186px;
  height: 220px;
  border: 1px solid $grey-5;
  border-radius: 8px;

  @include loadingAnimation;

  &__header{
    width: 100%;
    height: 100%;
  }

  &__footer{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__circle {
    width: 70px;
    height: 70px;
    background-color: $grey-5;
    border-radius: 50%;

  }

  &__divider {
    width: 100%;
    height: 1.5px;
    background-color: $grey-5;
  }

  &__lines {
    &__item {
      width: 125px;
      height: 12px;
      background-color: $grey-5;
      border-radius: 4px;

      &:not(:last-of-type) {
        margin-bottom: 10px;
      }

      &__short {
        width: 87px;
        height: 12px;
        background-color: $grey-5;
        border-radius: 4px;
      }
    }
  }
}
