@import 'vars.scss';

.autocomplete {
  width: 100%;
  margin-bottom: 8px;
  z-index: 2;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.06) !important;
  border-radius: 16px;

  &__endicon {
    margin-right: 9px;
  }

  & &__input {
    padding: 4px 15px !important;
    border-radius: 16px;
  }

  & &__input__outline {
    border: 1px solid $grey-5 !important;
    border-radius: 16px;
  }

  &--focused &__input__outline {
    border: 1px solid $grey-5 !important;
  }

  &--rounded#{&}--focused &__input__outline {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border: 1px solid $grey-5 !important;
    border-bottom-color: $white;
  }
}

.popper {
  margin: 0;
  top: 45px;
  position: absolute;
  z-index: 9999;

  & > div {
    box-shadow: none;
    padding: 0;
    border: 1px solid $grey-5;
    border-radius: 15px;
    border-top-color: $white;
    border-top-right-radius: 0;
    border-top-left-radius: 0;

    & > ul {
      @include scrollbar;
    }
  }
}
.option {
  display: flex;
  align-items: center;
  font-size: 20px;
  margin-top: 0 !important;
  padding: 4px 8px !important;
  border-radius: 16px;

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  &[aria-selected='true'] {
    background-color: $grey-6 !important;
  }

  &__avatar {
    @at-root div#{&} {
      width: 32px !important;
      height: 32px !important;
      font-size: 16px !important;
    }
  }

  &__highlight {
    font-size: 20px;
  }

  &__details {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__dot {
    margin: 0 8px;
    color: $grey-3;
    font-size: 30px;
  }

  &__inmateContact {
    font-size: 20px;
    color: $grey-3;
  }
}

.renderOption {
  margin-left: 2.5%;
}

.clearIcon {
  @at-root svg#{&} {
    cursor: pointer;
    width: 20px;
    height: 20px;
  }
}
