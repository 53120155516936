@import 'vars.scss';

.table_container {
  @include scrollbar;
}

table {
  width: 100%;
  border-collapse: collapse;

  font-size: 14px;
  z-index: 0;
}

thead, tbody {
  width: 100%;
}

thead {
  border-bottom: 1px solid #9191a1;
}

th, td {
  padding: 8px;
  max-height: 48px;
}

th:first-child {
  width: 30px;
}

th:nth-last-child(1) {
  width: 80px;
}


