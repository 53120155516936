@import 'vars.scss';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  & > span {
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    color: $grey-1;
  }
  &__btn {
    @at-root button#{&} {
      width: 48px;
      height: 48px;
      & > svg {
        width: 24px;
        height: 24px;
      }
    }
    @include iconButton(true);
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: calc(100vh - 100px);
  padding-bottom: 10px;
  overflow: auto;

  @include scrollbar();
}

.question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  border: 1px solid $grey-5;
  border-radius: 16px;

  &__left {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  &__number {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 8px;
    background: $grey-5;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    color: $grey-1;
  }

  &__name {
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    line-height: 22px;
    color: $grey-1;
    width: 200px;
    @include textEllipsis();
  }
}
