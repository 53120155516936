@import 'vars.scss';

button.button {
  margin-left: 20px;
  border: 1px solid $grey-5;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-transform: none;
  color: $grey-1;

  & > span {
    margin-left: 0;
  }
}

.modal {
  width: 580px;
  max-height: 80%;
  min-height: fit-content;
}

.info {
  position: absolute;
  padding-top: 40px;
  color: $grey-4;
}

.submit {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;

  & > button:first-of-type {
    width: 124px !important;
  }
}

.content {
  display: grid;
  grid-template-columns: auto auto auto auto;
  padding: 20px 26px;
  border-radius: 12px;
  min-height: 100px;
  max-height: 300px;
  background-color: $grey-6;
  overflow: hidden scroll;
  @include scrollbar;

  &__single_password {
    padding: 0 100px;
    margin: auto;
  }
}

.printable_image {
  & > img {
    margin-top: 0px;
    width: 100px;
    height: 100px;
    margin: 2px;
    border-radius: 8px;
  }
}

.printable_password {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  margin: 2px;
  border-radius: 8px;
  text-align: center;
  font-size: 12px;
  background-color: $white;
}

.print {
  display: none;
}

.temporary_password {
  display: flex;
  position: relative;
  font-size: 20px;
  font-weight: 500;
  background-color: $grey-5;
  border-radius: 16px;
  justify-content: center;

  span {
    padding: 20px;
  }
}
