@import 'vars.scss';

.page {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper {
  width: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

svg.logo {
  width: 272px;
  height: 40px;
}

.title {
  margin: 20px 0 40px;
  text-align: center;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: $grey-1;
}

.form {
  align-self: stretch;
  display: flex;
  flex-direction: column;
}

.input {
  &:first-of-type {
    margin-bottom: 32px;
  }
}

button.button {
  margin-top: 40px;
  padding: 11px 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  &:disabled {
    background-color: $grey-4;
  }
}

.passwordValidation {
  margin: 16px 0 20px 0;
  width: 100%;

  &__item {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 8px;
    }

    & > svg {
      width: 16px;
      height: 16px;
    }

    & > p {
      margin: 0 0 0 8px;
      font-size: 14px;
      line-height: 22px;
      color: $grey-4;
    }

    &--valid > p {
      color: $brand-green-2;
    }
  }
}
