@import '../../../vars';

.videoplayer {
  position: relative;
  height: 450px;
  min-width: 400px;
  max-width: 500px;
  background-color: $grey-1;
  border-radius: 8px;

  &__video_element {
    position: relative;
    height: 450px;
    // min-width: 400px;
    max-width: 480px;
    object-fit: contain;
  }

  &__controls {
    position: absolute;
    top: 87%;
    left: 2.5%;
    display: flex;
    flex-direction: row;
    height: 40px;
    width: 95%;
    flex-wrap: wrap;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 16px;
    -webkit-backdrop-filter: blur(40px);
    backdrop-filter: blur(40px);
    transition: all 0.2s;

    &__play_btn,
    &__volume_btn {
      height: 100%;
      min-width: 5%;
      top: 50%;
      left: 50%;
    }

    &__progress {
      width: 50%;

      &__bar {
        height: 100%;
        padding-top: 5px;
      }
    }

    &__time {
      height: 100%;
      width: 15%;
      color: #ffffff;
      display: inline-block;
      top: 50%;
      left: 50%;
      &__current,
      &__duration {
        display: inline-block;
        font-size: 14px;
        padding-top: 11px;
        inline-size: 32.813px;
      }
    }

    &__volume_control {
      height: 100%;
      width: 13%;
      &__bar {
        height: 100%;
        padding-top: 5px;
      }
    }

    &__fullscreen {
      height: 100%;
      width: 5%;
    }
  }
}

#play-pause-button,
#volume-button,
#fullscreen-button {
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: none;
  color: #ffffff;
  padding-top: 3.5px;
}

#video-volume-bar {
  -webkit-appearance: none;
  width: 95%;
  height: 2px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  background-image: linear-gradient(#ffffff, #ffffff);
  background-repeat: no-repeat;
}

/* SaveModalInput Volume Thumb */
#video-volume-bar::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background: #ffffff;
  cursor: ew-resize;
}

#video-volume-bar::-moz-range-thumb {
  -webkit-appearance: none;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background: #ffffff;
  cursor: ew-resize;
}

#video-volume-bar::-ms-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #ffffff;
  cursor: ew-resize;
}

#video-volume-bar::-webkit-slider-thumb:hover {
  background: #038768;
}

#video-volume-bar::-moz-range-thumb:hover {
  background: #038768;
}

#video-volume-bar::-ms-thumb:hover {
  background: #038768;
}

/* SaveModalInput Volume Track */
#video-volume-bar::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

#video-volume-bar::-moz-range-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

#video-volume-bar::-ms-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

#video-progress-bar {
  -webkit-appearance: none;
  width: 95%;
  height: 2px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  background-image: linear-gradient(#ffffff, #ffffff);
  background-repeat: no-repeat;
}

/* SaveModalInput Progress Thumb */
#video-progress-bar::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background: #ffffff;
  cursor: ew-resize;
}

#video-progress-bar::-moz-range-thumb {
  -webkit-appearance: none;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background: #ffffff;
  cursor: ew-resize;
}

#video-progress-bar::-ms-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #ffffff;
  cursor: ew-resize;
}

#video-progress-bar::-webkit-slider-thumb:hover {
  background: #038768;
}

#video-progress-bar::-moz-range-thumb:hover {
  background: #038768;
}

#video-progress-bar::-ms-thumb:hover {
  background: #038768;
}

/* SaveModalInput Progress Track */
#video-progress-bar::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

#video-progress-bar::-moz-range-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

#video-progress-bar::-ms-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}
