@import 'vars.scss';

.uploadButton {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 7px 20px 7px 12px;
  border: 1px solid $grey-5;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  text-transform: none;
  color: $grey-1;
  transition-property: background-color;
  transition-duration: 0.25s;
  background-color: #fbfbfb;
  & > input {
    display: none;
  }
  
}

.uploadButton:hover{
  background-color: $grey-6;
}

.modal {
  width: fit-content;
  max-width: 25%;
  min-width: 450px;

  &__header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 12px;
  }
 
  &__content {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 532px;
  }

  &__form{
    margin-top: 5%;
  }

  &__label {
    display: flex;
    gap: 8px;
    padding: 6px 12px;

    & > span {
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;

      &:first-of-type {
        color: $grey-4;
      }

      &:last-of-type {
        color: $grey-1;
      }
    }
  }

  &__facilities {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-sizing: border-box;
    height: 324px;
    padding: 10px 12px 10px 4px;
    overflow: hidden auto;
    border: 1px solid $grey-5;
    border-radius: 8px;

    @include scrollbar;
  }
}

.formStep {
  padding-top: 4px;
}

.actions {
  margin-top: 18px;
}

.input {
  margin-bottom: 26px;
}

.uploadModalDivider{
  border-radius: 2px;
  width: 97%;
  height: 2px;
  background-color: $grey-5;
  margin-bottom: 23px;

  &__uploadStep {
    border-radius: 2px;
    width: 97%;
    height: 2px;
    background-color: $grey-5;
    margin-bottom: 36px;
  }

  &__recipientStep{
    border-radius: 2px;
    width: 97%;
    height: 2px;
    background-color: $grey-5;
    margin-bottom: 20px;
  }

  &__reviewStep{
    border-radius: 2px;
    width: 97%;
    height: 2px;
    background-color: $grey-5;
    margin-bottom: 12px;
  }
}

.backButton {
  width: 124px;
}

.review {

  &__chip {
    padding: 2px 16px !important;
    border-radius: 999px;
    background-color: $grey-5;

    &__text {
      margin: 0;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: $grey-1;
    }
  }
  
  &__body{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    margin: 24px 0;
    font-size: 24px;
    line-height: 32px;
  }

  &__reviewDiv{
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 10px;
    width: 100%;
    
    &__recipientAccordianDiv {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      column-gap: 10px;
      width: 100%;  
    }

    &__sectionText{
      font-weight: 500;
    }
  }

}

.file__layout {
  display: inline-block;
}

.uploadedFile {
  padding: 6px 9.33px 6px 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  background-color: $grey-6;
  border-radius: 16px;

  &__info {
    margin: 0 9.33px 0 10px;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    font-weight: 500;
    font-size: 10px;
    line-height: 20px;

    &__ext {
      flex-shrink: 0;
      color: $grey-4;
    }
  }

}

.file {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 162px;
  padding: 7px 16px;
  border-radius: 8px;
  background: $grey-5;

  & svg {
    width: 24px;
    height: 24px;
  }
  &__name {
    max-width: 80px;
    overflow: hidden;
    font-size: 10px;
    font-weight: 500;
    line-height: 20px;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: $grey-1;
  }

  &__type {
    font-size: 10px;
    font-weight: 500;
    line-height: 20px;
    color: $grey-4;
  }

  &__trash {
    @at-root {
      & > svg {
        width: 16px !important;
        height: 16px !important;
      }
    }
  }
}
