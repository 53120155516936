@import 'vars.scss';

.page {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper {
  width: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title {
  margin: 0 0 16px;
  text-align: center;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  color: $grey-3;
}

svg.logo {
  width: 272px;
  height: 40px;
  margin-bottom: 48px;
}

.form {
  align-self: stretch;
  display: flex;
  flex-direction: column;
}

.input {
  &:first-of-type {
    margin-bottom: 20px;
  }

  &--hidden {
    display: none;
  }
}

.forgot {
  cursor: pointer;
  margin: 8px 0 24px;
  font-size: 16px;
  line-height: 24px;
  color: $grey-3;
  align-self: flex-end;
}

.sendedToEmailMsg {
  font-size: 14px;
  margin-top: 0;
  color: $success;
}
button.button {
  font-size: 20px !important;
  padding: 14px !important;
}
