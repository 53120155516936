@import 'vars.scss';

.modal {
  padding: 30px;
  width: 45%;

  &__title {
    padding-bottom: 0;
  }

  &__details {
    &__info {
      height: 170px;

      & > img {
        width: 99%;
      }
    }

    span {
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
    }
  }
}

.form {
  border: none;

  &__input {
    border-radius: 8px;
  }

  &__actions {
    margin-top: 20px;
  }

  &__cancelButton {
    width: 124px;
    height: 48px;
  }

  &__submitButton {
    border: none;
    width: 101px;
    height: 48px;
  }
}

.participant__card {
  &__info {
    border: none;

    &__label {
      border: none;
    }
    &__value {
      color: $grey-4;
    }
  }
  &__avatar {
    margin: 0 10px 0 0;
  }
}

.thumbnail {
  height: 100%;
  width: 100%;
  padding: 0;
  border-radius: 8px;

  &__altThumbnail {
    background-color: rgba(0, 0, 0, 0.85);
    height: 162px;
    backdrop-filter: blur(20px);
  }

  &__image_right,
  &__image_left {
    width: 100%;
    height: 162px;
    border-radius: 8px 0px 0px 8px;
    object-fit: fill;
  }
  &__image_right {
    border-radius: 0px 8px 8px 0px;
    border: none;
  }

  &__avatar {
    @at-root div#{&} {
      width: 40px;
      height: 40px;
      font-size: 14px;
      margin-top: 50px;
      margin-bottom: 15px;
    }
  }
  &__info {
    color: $grey-6;
    margin-bottom: 30px;
  }

  &__duration,
  &__type {
    position: absolute;
    z-index: 2;
    top: 85px;
    left: 40px;
    padding: 0 8px;
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.4);
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    color: $white;
  }
  &__type {
    left: 96px;
  }
}

.divider {
  width: 1px;
  height: 160px;
  background-color: $grey-5;
  margin: 0 5px 0 15px;
}
