@import 'vars.scss';

.info_wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto hidden;
  border-radius: 16px;
  border: 1px solid $grey-5;
  background-color: $white;
  box-shadow: 0px 6px 13px rgba(228, 228, 228, 0.7);
  padding: 20px 24px;
  // height: calc(100% - px);
}

.actions {
  position: absolute;
  top: 0;
  left: 0;

  &__item {
    @at-root button#{&} {
      padding: 5px 16px;
      margin: 15px 15px 0 15px;
      text-transform: none;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: $grey-1;
    }

    &:first-of-type {
      margin-left: 8px;
    }
  }
}

.name {
  margin: 0 auto 8px;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
}

.info {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 49px;

  &__item {
    display: flex;
    align-items: center;
    margin: 0;
    font-size: 14px;
    color: $grey-3;
  }
  &__doc {
    position: absolute;
    background: $brand-green-2;
    border-radius: 16px;
    width: 113px;
    height: 26px;
    margin-top: 8px;
    text-align: center;
    font-size: 14px;
    color: $grey-3;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $white;
  }
}

.section {
  padding-bottom: 4px;
  border-bottom: 2px solid $grey-6;

  &__title {
    margin: 0;
    padding: 4px 0;
    font-size: 16px;
    line-height: 24px;
    color: $grey-1;
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    padding: 16px 0;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 40px;

    & > span {
      position: relative;
      display: flex;
      padding: 2px 10px;
      height: 26px;
      background: $grey-5;
      border-radius: 16px;
      margin: 10px;
      font-size: 16px;
    }
  }
}

button.button {
  padding: 5px 9px;
  text-transform: none;
  border: 1px solid $grey-5;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: $grey-3;
  margin-right: -9px;
}
