.wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.loader {
  margin: 10px;
  width: 32px !important;
  height: 32px !important;
}
