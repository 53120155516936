@import '/src/vars.scss';

.message {
  &__owner_info {
    margin-top: 8px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;

    &__avatar {
      @at-root div#{&} {
        width: 32px;
        height: 32px;
        font-size: 14px;
      }
    }

    &__name {
      cursor: pointer;
      margin: 0 8px;
      font-weight: 700;
      font-size: 14px;
      line-height: 22px;
      color: $grey-1;
    }
  }
}

div.messages {
  display: flex;
  flex-direction: column-reverse;
  box-sizing: border-box;
  position: relative;
  height: calc(100vh - 225px);
  padding: 16px 24px;
  overflow: hidden auto;

  @include scrollbar;

  &__loader {
    margin: auto;
  }
}

.message {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  &--mine {
    align-items: flex-start;
  }

  &--mine > &__text {
    flex-direction: row;

    & > p {
      background-color: $grey-1;
      border: 1px solid $grey-1;
      border-radius: 16px 16px 16px 4px;
      color: $white;
    }

    & > svg {
      margin: 0 7px 0 11px;
    }
  }

  &--mine > &__audio {
    flex-direction: row;

    & > p {
      background-color: $grey-1;
      border: 1px solid $grey-1;
      border-radius: 16px 16px 16px 4px;
      color: $white;
    }

    & > svg {
      margin: 0 7px 0 11px;
    }
  }

  &__text {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }

  &__text {
    &--blocked > p {
      color: $white !important;
      border-color: $error !important;
      background-color: $error !important;
    }

    & > p {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      margin: 0;
      gap: 8px;
      max-width: 480px;
      padding: 12px 16px;
      border: 1px solid $grey-5;
      border-radius: 16px 16px 4px 16px;
      background-color: $white;
      color: $grey-1;
    }
  }

  &__date {
    display: flex;
    align-items: flex-end;
    padding-top: 4px;
    font-size: 12px;
    line-height: 20px;
    color: $grey-3;

    column-gap: 4.67px;

    &--seen {
      svg {
        color: $brand-green-1;
      }
    }

    &--blocked {
      color: $white;

      svg {
        color: transparentize($white, 0.4);
      }
    }
  }

  &__readMark {
    &--blockedSeen {
      color: white !important;
    }
  }

  &--mine & {
    &__date {
      color: $white;

      svg {
        color: transparentize($white, 0.4);
      }

      &--seen {
        svg {
          color: $white;
        }
      }
    }
  }

  &__text {
    & > svg {
      margin: 0 11px 0 7px;
      width: 20px;
      height: 20px;
    }

    & > span {
      display: block;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: $error;
    }
  }

  &__audio {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;

    &--mine {
      flex-direction: row;
      border-radius: 16px 16px 16px 4px;

      p {
        background-color: $grey-1;
        color: white;
      }
    }

    & > p {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      margin: 0;
      gap: 8px;
      max-width: 470px;
      padding: 12px 16px;
      border: 1px solid $grey-5;
    }

    &__wrapper {
      padding: 12px 16px;
      border-radius: 16px 16px 4px 16px;
      border: 1px solid $grey-5;

      &--mine {
        background-color: $grey-1;
        border-radius: 16px 16px 16px 4px;
        color: white;
        flex-direction: row-reverse !important;
      }

      &--mine > p {
        border-radius: 16px 16px 16px 4px;
      }
    }

    &__transcription {
      max-width: 438px;
      padding: 12px 16px;
      margin: 1px 0;

      overflow: hidden auto;
      @include scrollbar;

      &--mine {
        background-color: $grey-1;
        color: $white;
      }
    }

    & > svg {
      margin: 0 11px 0 7px;
      width: 20px;
      height: 20px;
    }

    &__transcriptionIcon {
      margin: 0 14px;
    }
  }

  &__audio {
    &--blocked > p {
      color: $white !important;
      border-color: $error !important;
      background-color: $error !important;
    }

    & > svg {
      margin: 0 11px 0 7px;
      width: 20px;
      height: 20px;
    }
  }

  &__image {
    cursor: pointer;
    position: relative;
    border-radius: 16px;
    width: 280px;
    height: 280px;
    overflow: hidden;

    &--has-caption {
      border-radius: 16px 16px 0 0;
    }

    &--isVideoCall {
      width: 470px;
      height: 260px;
    }

    & > img {
      width: 100%;
      height: 100%;
    }

    & > video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    & > button {
      border: none;
      background-color: transparent;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &__overlay {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.25);

      & > p {
        margin: 0;
        width: 75%;
        text-align: center;
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        color: $white;
        text-shadow: 0 0.695925px 1.39185px rgba(0, 0, 0, 0.35);
      }
    }

    &__transcriptionIcon {
      position: absolute;
      margin-top: 150px;
      z-index: 3;
      margin-right: 300px;

      &__mine {
        margin-left: 300px;
      }
    }
  }

  &--mine > &__info {
    flex-direction: row;
  }

  &__info {
    margin-top: 8px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;

    &__avatar {
      @at-root div#{&} {
        width: 32px;
        height: 32px;
        font-size: 14px;
      }
    }

    &__name {
      cursor: pointer;
      margin: 0 8px;
      font-weight: 700;
      font-size: 14px;
      line-height: 22px;
      color: $grey-1;
    }
  }
}

.date {
  align-self: stretch;
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 24px;

  & > p {
    margin: 0;
    padding: 4px 16px;
    border-radius: 999px;
    border: 1px solid $grey-4;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    color: $grey-1;
    background-color: $white;
  }

  & > div {
    width: 100%;
    height: 1px;
    //background-color: $grey-6;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 0;
  }
}
