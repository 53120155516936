@import 'vars.scss';

.loader {
  &__spinner {
    @at-root span#{&} {
      color: $grey-1;
      width: 20px !important;
      height: 20px !important;
    }
  }

  &__text {
    color: $grey-3;
    margin: 0 0 0 8px;
    font-size: 14px;
    line-height: 22px;
  }
}
