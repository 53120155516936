@import 'vars.scss';

button.button {
  margin-left: 20px;
  padding: 10px 12px;
  border: 1px solid $grey-5;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-transform: none;
  color: $grey-1;

  & > span {
    margin-left: 0;
  }
}

.modal {
  width: fit-content;
  &__container {
    display: flex;
    flex-direction: row;
    column-gap: 30px;
    
  }

  &__documentFilterHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
  }

  &__column {
    width: 255px;
    height: 286px;
  }
}

.block {
  width: 255px;

  &__label {
    display: flex;
    align-items: center;
    margin: 0 0 8px 0;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    color: $grey-1;

    & > span {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 0 0 8px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: $grey-1;
      color: $white;

      & > span {
        display: block;
        text-align: center;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-sizing: border-box;
    height: calc(100% - 30px);
    padding: 10px 12px 10px 4px;
    overflow: hidden auto;
    border: 1px solid $grey-5;
    border-radius: 8px;

    @include scrollbar;
  }
}

.switch {
  margin-bottom: 24px;
  &__label {
    margin: 0;
    margin-bottom: 3px;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    color: $grey-1;
  }
}
