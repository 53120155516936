@import 'vars.scss';

.modal {
  width: 584px;
}

button.button {
  margin-left: 16px;
  padding: 12px 32px 12px 19px;
  border-radius: 8px;
  overflow: hidden;
  text-transform: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  white-space: nowrap;
  color: $grey-3;
  border: 1px solid $grey-5;

  & > span:last-of-type {
    border-radius: unset;
  }
}

button.backButton {
  width: 124px;
}

.formStep {
  padding-top: 34px;
}

div.input {
  margin-bottom: 26px;
}

.actions {
  margin-top: 32px;
}

.recipients {
  &__header {
    margin: 0 0 2px 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

  &__tip {
    margin: 0 0 16px 0;
    font-size: 14px;
    line-height: 22px;
    color: $grey-3;
  }

  &__type {
    margin-bottom: 18px !important;
  }

  &__input {
    @at-root div#{&} {
      margin-bottom: 16px;
    }
  }

  &__placeholder {
    height: 124px;
    border-radius: 8px;
    background: $grey-6;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__icon {
      @at-root svg#{&} {
        width: 40px;
        height: 40px;
      }
    }

    &__text {
      margin: 4px 0 0 0;
      color: $grey-3;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
    }

    &__subtext {
      margin: 0;
      font-size: 12px;
      line-height: 20px;
      color: $grey-4;
    }
  }
}

.review {
  &__title {
    margin: 24px 0;
    font-size: 24px;
    line-height: 32px;
  }

  &__label {
    margin: 0 0 8px 0;
    font-size: 16px;
    line-height: 24px;
    color: $grey-3;
  }

  &__recipients {
    @at-root div#{&} {
      width: 100%;
      margin: -8px 0 32px -8px;
    }
  }

  &__chip {
    padding: 2px 16px !important;
    border-radius: 999px;
    background-color: $grey-5;

    &__text {
      margin: 0;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: $grey-1;
    }
  }

  &__text {
    margin: 0 0 80px 0;
    max-height: 200px;
    overflow-y: auto;
    font-size: 20px;
    line-height: 28px;
    color: $grey-1;

    @include scrollbar();
  }
}
