@import 'vars.scss';

button.cancelButton {
  width: 124px;
  margin-right: 16px;
}

.title {
  margin: 0 32px !important;
}

.modal {
  height: 80%;
  padding: 24px 0 !important;
  width: 600px !important;
}

.form {
  display: flex;
  flex-direction: column;
  height: calc(100% - 52px);

  &__content {
    padding: 10px 32px 0 32px;
    height: calc(100% - 70px);
    overflow: auto;

    @include scrollbar;
  }

  &__input {
    margin-bottom: 20px;
  }

  &__subtitle {
    margin: 20px 0 16px 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $grey-1;
  }

  &__permissions {
    flex-grow: 1;
    @include scrollbar;
  }

  &__actions {
    display: flex;
    padding: 24px 24px 0 24px;
    box-shadow: 0px -7px 16px rgba(0, 0, 0, 0.05);
  }
}
