@import 'vars.scss';

.wrapper {
  display: flex;
  align-items: center;
}

.text {
  min-width: 80px;
  margin: 0 16px 0 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: $grey-1;

  & > span {
    color: $grey-4;
  }
}

.action {
  @at-root button#{&} {
    margin-right: 8px;
    min-width: unset;
    padding: 6px;
    border: 1px solid $grey-5;
    border-radius: 8px;
  }

  & > svg {
    width: 20px;
    height: 20px;
  }

  & > span {
    color: rgba(0, 0, 0, 0.54);
  }
}
