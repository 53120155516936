@import 'vars.scss';

.modal {
  width: 50%;
  max-width: 800px;
  @media only screen and (max-width: 1024px) {
  }
}

.button {
  width: 124px;
}

.file {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 9px;
  margin-bottom: 16px;
  width: fit-content;
  padding: 8px 16px;
  border-radius: 16px;
  border-radius: 16px;
  background-color: $grey-6;
  & > svg {
    color: $grey-3;
  }

  &__info {
    display: flex;
    justify-content: center;
    font-size: 10px;
    letter-spacing: 0;
    font-weight: 500;
    line-height: 20px;
  }

  &__name {
    display: block;
    max-width: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: $grey-1;
  }

  &__ext {
    color: $grey-4;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 32px;
  &__title {
    margin: 0;
    max-width: 600px;
    font-size: 20px;
    font-weight: 700;
    font-style: normal;
    line-height: 28px;
    color: $grey-1;
  }
  &__controls {
    display: flex;
    gap: 8px;
  }

  &__btn {
    @at-root button#{&} {
      & > svg {
        width: 24px;
        height: 24px;
        color: $grey-1;
      }
    }
  }
}

.body {
  &__info {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 32px;
  }
  &__message {
    margin: 0;
    margin-bottom: 16px;
    max-height: 200px;
    overflow-y: auto;

    @include scrollbar();
  }

  &__timeStamp {
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: $grey-3;
  }

  &__user {
    cursor: pointer;
    margin: 0;
    color: $brand-green-2;
    &:hover {
      text-decoration: underline;
    }
  }

  &__inmate {
    cursor: pointer;
    margin-right: 1%;
    color: $brand-green-2;
    &:hover {
      text-decoration: underline;
    }
  }

  &__facility {
    color: $grey-3;
  }

  &__subTitle {
    margin: 0;
    margin-bottom: 4;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    line-height: 24px;
    color: $grey-1;
  }

  &__recipients {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  &__views,
  &__recipients {
    display: flex;
    align-items: center;
    gap: 15px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: $grey-3;
  }
  &__recipients {
    gap: 5px;
  }
}

.singleRecipient {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
}

.accordion {
  width: 100%;
  max-height: 151px;
  overflow: auto;
  background-color: $grey-6 !important;
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;

  @include scrollbar;
}

@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html,
  body {
    height: initial !important;
  }
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }
  .header__controls {
    display: none;
  }
  .button {
    display: none !important;
  }
}

@page {
  size: auto;
  margin: 20mm;
}
