@import 'vars.scss';

.checkbox {
  @at-root span#{&} {
    padding: 0;
    margin: 0 8px;
  }

  & > svg {
    font-size: 20px;
  }

  &__wrapper {
    @at-root label#{&} {
      margin: 0;
      padding: 8px 0;

      &--group {
        width: calc(100% - 24px);
      }

      &--item {
        width: calc(100% - 40px);
      }
    }
  }

  &__label {
    @at-root span#{&} {
      width: calc(100% - 36px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 16px;
      line-height: 24px;
      color: $grey-1;
    }
  }
}

.group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  &__icon {
    cursor: pointer;
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    transition: 0.3s;

    &--up {
      transform: rotate(180deg);
    }
  }
}

.nestedItem {
  display: flex;
  align-items: center;
  width: 100%;
}

.search {
  &__wrapper {
    box-sizing: border-box;
    margin: 0 0 4px 0 !important;
    width: 100%;
    padding: 0 0 0 8px !important;

    &  input {
      &::placeholder {
        color: $grey-4;
        opacity: 1;
      }
    }
  }
}
