@import 'vars.scss';

section {
  height: calc(85vh - 50px);
}

.tabs {
  border-bottom: 2px solid $grey-6;

  &__indicator {
    @at-root span#{&} {
      background-color: $grey-1;
      min-width: unset;
    }
  }

  &__item {
    @at-root button#{&} {
      margin-right: 34px;
      min-width: unset;
      padding: 4px 0;
      font-size: 16px;
      line-height: 24px;
      text-transform: none;
      text-align: left;
      color: $grey-4;
    }

    &#{&}--active {
      @at-root button#{&} {
        color: $grey-1;
      }
    }
  }

  &__content {
    height: calc(80vh - 55px);
    padding-right: 10px;
    overflow-y: auto;
    @include scrollbar;
  }
}

.permission__header {
  margin-top: 20px;

  &__title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    padding: 0;
  }
}

.singles_permissions {
  list-style-type: none;
  padding-left: 0px;

  &__foil {
    padding: 0px 0px 8px 0px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    &--noValid {
      color: $grey-3;
    }
    &__icon {
      svg {
        padding-bottom: 3px;
        // margin-left: 26px;
        margin: 0px 6px 0px 0px;
        // width: 16px;
        // height: 16px;
      }
    }
  }
}
