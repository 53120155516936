.page {
  page-break-after: always;
  //width: 21cm;
  //min-height: 1150px;
  //padding-top: 200px;
  //border: 1px #d3d3d3 solid;
  //border-radius: 5px;
  //background: white;
  //box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  //
  //box-sizing: border-box;
  //-moz-box-sizing: border-box;

  img {
    width: 510px;
  }
}
